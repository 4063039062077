import Language from "../../Interfaces/Language";

export const pl: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853737-czesto-zadawane-pytania",
    copyright: "Prawa autorskie",
    copyrightNotices: "Informacje o prawach autorskich",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994395-informacje-o-prawach-autorskich",
    terms: "Warunki korzystania",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994328-warunki-korzystania",
    privacy: "Oświadczenie o ochronie prywatności",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990127-oswiadczenie-o-ochronie-prywatnosci",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857172-polityka-dmca",
    accommodations: "Niepełnosprawność/Udogodnienia",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994464-niepelnosprawnosc-udogodnienia",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Niniejsza ocena zawiera materiał zreprodukowany z SalesAP ©1995 i CSAP ©2002, Multi-Health Systems Inc. Wszelkie prawa zastrzeżone.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "ok.",
    minutes: "min"
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Ocena została wstrzymana.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "<b>Nie</b> opuszczaj okna przeglądarki ani nie klikaj poza obszarem oceny w trakcie trwania testu.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "W przypadku problemów z połączeniem internetowym ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "kliknij tutaj",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      ", aby uzyskać wskazówki dotyczące rozwiązywania problemów.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Opuszczenie oceny spowoduje jej <b>blokadę</b> ze względów bezpieczeństwa testu, co może uniemożliwić ukończenie oceny.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Pamiętaj, że jeśli opuścisz ocenę jeszcze 2 razy, dojdzie do <b>blokady</b> z uwagi na bezpieczeństwo testu.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Jeśli ponownie opuścisz ocenę, dojdzie do blokady z uwagi na bezpieczeństwo testu.</b>",
    BUTTON_RESUME: "Wznów"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://www.criteriacorp.com.au/candidates/candidate-faqs"
    }
  }
};
