import React, { FunctionComponent, useRef, useState } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import GameTest from "../../Tests/TestDisplays/GameTest/GameTest";
import GameTutorial from "../../Tests/TestDisplays/GameTest/GameTutorial/GameTutorial";
import { getGameTestStartingQuestion } from "../../utils/redisHelpers";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const Game: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getGameTestStartingQuestion>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });
  const [isTutorial, setIsTutorial] = useState(true);
  const [currentGameId, setCurrentGameId] = useState(1);
  const startingQuestion = useRef(0);

  const gameStartHandler = () => {
    setIsTutorial(current => !current);
  };

  const gameResetHandler = () => {
    startingQuestion.current = 0;
    setCurrentGameId(current => current + 1);
  };

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  if (isTutorial) {
    return (
      <GameTutorial
        testData={props.testData}
        timeFactor={props.timeFactor}
        gameTutorialId={currentGameId}
        handleStartRealTest={gameStartHandler}
      />
    );
  }
  return (
    <GameTest
      {...props}
      startingQuestion={startingQuestion.current}
      currentGameId={currentGameId}
      forceGameStart
      resetStartingQuestion={gameResetHandler}
    />
  );
};

export default Game;
