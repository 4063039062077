import Language from "../../Interfaces/Language";

export const sk: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853749-najcastejsie-otazky",
    copyright: "Autorské práva",
    copyrightNotices: "Doložky o autorských právach",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994397-dolozky-o-autorskych-pravach ",
    terms: "Podmienky používania ",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994330-podmienky-pouzivania",
    privacy: "Vyhlásenie o ochrane osobných údajov",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990140-vyhlasenie-o-ochrane-osobnych-udajov",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/9945988-politika-suladu-so-zakonom-o-autorskych-pravach-pre-digitalne-tisicrocie-dmca",
    accommodations: "Zdravotné postihnutie/prispôsobenie",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994467-zdravotne-postihnutie-prisposobenie",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Toto hodnotenie zahŕňa materiály prevzaté zo SalesAP ©1995 a CSAP ©2002, Multi-Health Systems Inc. Všetky práva vyhradené.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA autorské práva © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "približne",
    minutes: "min."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Vaše hodnotenie bolo pozastavené.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "V priebehu testu <b>neopúšťajte</b> okno prehliadača ani neklikajte mimo hodnotiacej plochy.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Ak sa vyskytnú problémy s pripojením na internet, ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "kliknite sem",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " a zobrazia sa tipy na riešenie problémov.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "V prípade, že opustíte hodnotenie, bude váš test z bezpečnostných dôvodov <b>zablokovaný</b> a môže sa stať, že nebudete môcť hodnotenie dokončiť.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Upozorňujeme, že ak opustíte hodnotenie ešte dvakrát, bude váš test z bezpečnostných dôvodov <b>zablokovaný</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>V prípade, že znova opustíte hodnotenie, bude váš test z bezpečnostných dôvodov zablokovaný.</b>",
    BUTTON_RESUME: "Pokračovať"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://www.criteriacorp.com.au/candidates/candidate-faqs"
    }
  }
};
