import React, { FunctionComponent } from "react";

type MessageProps = {
  message: string;
};
const bold = RegExp(/<b[^>]*>([^<]+)<\/b>/g);
const FormattedMessage: FunctionComponent<MessageProps> = ({ message }) => {
  return <p>{formatBold(message)}</p>;
};

function formatBold(msg: string): JSX.Element {
  const match = Array.from(msg.matchAll(bold))[0];
  if (!match) {
    return <>{msg}</>;
  }
  const [b, a] = msg.split(match[0]);
  return (
    <>
      {b}
      <b>{match[1]}</b>
      {formatBold(a)}
    </>
  );
}

export default FormattedMessage;
