import Language from "../Interfaces/Language";
import * as languages from "../Resources/languages";

const {
  cs,
  da,
  de,
  en_au,
  en_gb,
  en_us,
  es_es,
  es_la,
  fr_ca,
  fr_fr,
  hr,
  it,
  ja,
  ko,
  nl,
  pl,
  pt_br,
  pt_pt,
  ro,
  sk,
  ar,
  sv,
  tr,
  zh_cn
} = languages;

/**
 * @desc Reads test event data to return the language needed for translation.
 * @desc NOTE: This function is temporary/subject to change if we deprecate reliance on DB translatedText values.
 * @param testEventData Object of test event data which may have translatedText and locale values.
 * @param isAU Optional boolean taken from TestEventContext to determine Australian English translation.
 * @return {string} Returns string language name or code.
 */
export const getTranslationLanguage = (
  testEventData: any,
  isAU: boolean = false
): string => {
  const hostnameArray = window.location.hostname.split(".");
  const subdomain = hostnameArray[0].toUpperCase();

  const testEventLanguage = testEventData?.translatedText?.language;

  if (testEventLanguage && testEventLanguage !== "english") {
    return testEventLanguage;
  }

  if (testEventData?.locale === "AU" || isAU || subdomain === "AU") {
    return "au";
  }

  return "en-us";
};

/**
 * @desc Sets the language file based on the provided language code.
 * @param language String code representing the language to be used, ex. "en-us", "fr-ca".
 * @return {Language} Returns the language file corresponding to the provided language code. Defaults to English (en_us) if the language code is not recognized.
 */
export const setLangFile = (language: string = "en-us"): Language => {
  let langFile;

  switch (language.toLowerCase()) {
    case "cs":
    case "czech":
      langFile = cs;
      break;
    case "da":
    case "danish":
      langFile = da;
      break;
    case "de":
    case "german":
      langFile = de;
      break;
    case "en-au":
    case "english-australia":
    case "au":
      langFile = en_au;
      break;
    case "en-gb":
    case "uk":
      langFile = en_gb;
      break;
    case "es-es":
    case "spanish-spain":
      langFile = es_es;
      break;
    case "es-la":
    case "spanish":
      langFile = es_la;
      break;
    case "fr-ca":
    case "french-canada":
      langFile = fr_ca;
      break;
    case "fr-fr":
    case "french":
    case "french-france":
      langFile = fr_fr;
      break;
    case "hr":
    case "croatian":
      langFile = hr;
      break;
    case "it":
    case "italian":
      langFile = it;
      break;
    case "ja":
    case "japanese":
      langFile = ja;
      break;
    case "ko":
    case "korean":
      langFile = ko;
      break;
    case "nl":
    case "dutch":
      langFile = nl;
      break;
    case "pl":
    case "polish":
      langFile = pl;
      break;
    case "pt-br":
    case "portuguese-brazil":
      langFile = pt_br;
      break;
    case "pt-pt":
    case "portuguese":
      langFile = pt_pt;
      break;
    case "ro":
    case "romanian":
      langFile = ro;
      break;
    case "sk":
    case "slovak":
      langFile = sk;
      break;
    case "ar":
    case "arabic":
      langFile = ar;
      break;
    case "sv":
    case "swedish":
      langFile = sv;
      break;
    case "tr":
    case "turkish":
      langFile = tr;
      break;
    case "zh-cn":
    case "chinese":
      langFile = zh_cn;
      break;

    case "en-us":
    case "english":
    case "us":
    default:
      langFile = en_us;
      break;
  }

  return langFile;
};

/**
 * @desc Takes the name of a text property and returns its value in the appropriate language.
 * @param textType String "category" of the text value to be found in a language file, ex. "common", "instruction".
 * @param textKey String "name" of a text value to be found as a key in a language file, ex. "helpCenter".
 * @param language Optional string param of a language code to translate to, defaults to "en-us" if not passed in.
 * @return {string | undefined} Returns string text value if found in a language file, otherwise empty string or undefined.
 */
export const translate = (
  textType: string,
  textKey: string,
  language: string = "en-us"
): string => {
  const langFile = setLangFile(language);
  const defaultLangFile = en_us;

  // Attempt to get the translation from the specified language
  let typeTranslations = langFile[textType as keyof Language];
  let translation = typeTranslations ? typeTranslations[textKey] : undefined;

  // If not found and the language is not English, default to English
  if (translation === undefined && language !== "en-us") {
    typeTranslations = defaultLangFile[textType as keyof Language];
    translation = typeTranslations ? typeTranslations[textKey] : undefined;
  }

  // If still not found, return an empty string
  return translation ?? "";
};
