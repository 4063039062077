import { determineNextGame } from "../Tests/helpers";
import { nodeApiURL } from "./constants";

export const getMultipleChoiceStartingQuestion = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<{ questionNumber: number; expiredTime: number } | undefined> => {
  const testData = {
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(
      `${nodeApiURL}/getMultipleChoiceQuestionNumber`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(testData)
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining multiple choice test question: ", error);
  }
};

export const getPersonalityTestStartingQuestion = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<{ pageNumberToResume: number } | undefined> => {
  const testData = {
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(
      `${nodeApiURL}/getPersonalityTestQuestionNumber`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(testData)
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining personality test question: ", error);
  }
};

export const getGameTestData = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<any> => {
  const formData = { testEventId, subTestId };
  try {
    const response = await fetch(`${nodeApiURL}/getGameTestData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("There was no Game test data.");
  }
};

export const getGameTestStartingQuestion = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<{ questionNumber: number } | undefined> => {
  const testData = {
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(`${nodeApiURL}/getGameTestStartingQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(testData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining GAME test question: ", error);
  }
};

export const postGameTestData = async (
  testEventId: number,
  subTestId: string,
  testData: any,
  token: string
): Promise<void> => {
  const formData = {
    testEventId,
    subTestId,
    testData
  };
  try {
    const response = await fetch(`${nodeApiURL}/postGameTestData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("There was an error saving the test data: ", error);
  }
};

export const getCLIKStartingQuestion = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<
  | {
      startingSection: number;
      startingQuestion: number;
      expiredTime: number;
    }
  | undefined
> => {
  const testData = {
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(`${nodeApiURL}/getCLIKStartingQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(testData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining CLIK test question: ", error);
  }
};

export const sendMrabAnswersToRedis = async (
  testEventId: number,
  taskId: number,
  subTestId: number,
  updateTaskDataSaved: (value: boolean) => void,
  token: string,
  setEnableButton: (value: boolean) => void
): Promise<void> => {
  let taskData: any = localStorage.getItem("a");
  taskData = JSON.parse(taskData);
  const formData = {
    testEventId,
    subTestId,
    taskId,
    taskData
  };
  try {
    const response = await fetch(`${nodeApiURL}/postMrabTaskData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(formData)
    });
    if (response.status === 200) {
      if (taskId === 9) {
        updateTaskDataSaved(true);
        localStorage.clear();
      } else {
        setEnableButton(true);
      }
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("There was an error saving the MRAB/CAST test data: ", error);
  }
};

export const getMrabStartingTask = async (
  testEventId: number,
  subTestId: string,
  token: string
): Promise<{ startingTaskNumber: number } | undefined> => {
  const testData = {
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(`${nodeApiURL}/getMrabStartingTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(testData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining MRAB/CAST starting Task: ", error);
  }
};

export const sendTimeRemaining = async (
  timeRemaining: number,
  testEventId: number,
  subTestId: string,
  token: string
) => {
  const formData = {
    timeRemaining,
    testEventId,
    subTestId
  };
  try {
    const response = await fetch(`${nodeApiURL}/setTimeRemaining`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("There was an error sending the time remaining: ", error);
    return false;
  }
};

export const getAllTestMakerAnswers = async (
  testEventId: number,
  testMakerTestId: string,
  token: string
): Promise<{ answer: Record<string, string> } | undefined> => {
  const formData = {
    testEventId,
    testMakerTestId
  };
  try {
    const response = await fetch(`${nodeApiURL}/getAllTestMakerAnswers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(formData)
    });
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.log("There was an error in getting TM Test Answer: ", error);
  }
};

export const getAdaptiveStartingQuestion = async (
  testEventId: number,
  subTestId: number,
  gameId: number | null,
  token: string,
  initialTheta: number,
  isResumingTest: boolean
): Promise<void> => {
  const testData = {
    testEventId,
    subTestId,
    gameID: gameId,
    initialTheta,
    isResumingTest
  };
  try {
    const response = await fetch(`${nodeApiURL}/getAdaptiveStartingQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(testData)
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error in determining GAME test question: ", error);
  }
};

export const getTestMakerData = async (
  testEventId: number,
  testMakerTestId: string,
  token: string,
  showPagination: boolean = false
): Promise<
  | {
      questionNumber: number;
      expiredTime: number;
      answeredQuestions: string[];
    }
  | undefined
> => {
  const [multipleChoiceStartingQuestion, testMakerAnswers] = await Promise.all([
    getMultipleChoiceStartingQuestion(testEventId, testMakerTestId, token),
    showPagination &&
      getAllTestMakerAnswers(testEventId, testMakerTestId, token)
  ]);
  let answerArray: string[] = [];
  if (testMakerAnswers) {
    answerArray = Object.keys(testMakerAnswers.answer).reduce<string[]>(
      (p, c) => {
        const answer = c.split(":");
        if (answer[0] === "answer" && testMakerAnswers.answer[c] !== "") {
          return [...p, answer[1]];
        } else {
          return p;
        }
      },
      []
    );
  }
  if (multipleChoiceStartingQuestion) {
    return {
      ...multipleChoiceStartingQuestion,
      answeredQuestions: answerArray
    };
  } else {
    return {
      questionNumber: 0,
      expiredTime: 0,
      answeredQuestions: answerArray
    };
  }
};

export const getResumeTestDataFetchMethod = (testType: string) => {
  switch (testType) {
    case "multipleChoiceTest":
    case "testMakerTest":
      return getMultipleChoiceStartingQuestion;
    case "personalityTest":
      return getPersonalityTestStartingQuestion;
    case "gameTest":
      return getGameTestData;
    case "CLIKTest":
      return getCLIKStartingQuestion;
    case "mrabCastTest":
      return getMrabStartingTask;
    case "revelianSubTest":
      return determineNextGame;
    case "typingTest":
    case "tenKeyTest":
    default:
      return null;
  }
};
