/* eslint-disable @typescript-eslint/no-redeclare */
import React, { FunctionComponent, PropsWithChildren } from "react";

import styles from "./Alert.module.css";

export const AlertEdge = {
  NONE: "none",
  LEFT: "left"
} as const;
export type AlertEdge = (typeof AlertEdge)[keyof typeof AlertEdge];

export const AlertTextWeight = {
  LIGHT: "light",
  HEAVY: "heavy"
} as const;
export type AlertTextWeight =
  (typeof AlertTextWeight)[keyof typeof AlertTextWeight];

export const AlertColor = {
  BLUE: "blue",
  YELLOW: "yellow",
  GREY: "grey",
  RED: "red",
  SAPPHIRE: "sapphire"
} as const;
export type AlertColor = (typeof AlertColor)[keyof typeof AlertColor];

export const AlertTextColor = {
  BLUE: "blue",
  BLACK: "black",
  SAPPHIRE: "sapphire"
} as const;
export type AlertTextColor =
  (typeof AlertTextColor)[keyof typeof AlertTextColor];

export const AlertSize = {
  LARGE: "large",
  PARENT: "parent",
  DISCLAIMER: "disclaimer",
  SMALL: "small"
} as const;
export type AlertSize = (typeof AlertSize)[keyof typeof AlertSize];

type AlertProps = PropsWithChildren<{
  alertTextWeight?: AlertTextWeight;
  alertEdge?: AlertEdge;
  alertColor?: AlertColor;
  alertTextColor?: AlertTextColor;
  alertSize?: AlertSize;
  hasTransition?: boolean;
  hasBoxShadow?: boolean;
}>;

export const Alert: FunctionComponent<AlertProps> = ({
  alertEdge = AlertEdge.NONE,
  alertTextWeight = AlertTextWeight.HEAVY,
  alertColor = AlertColor.BLUE,
  alertTextColor = AlertTextColor.BLUE,
  alertSize = AlertSize.PARENT,
  hasTransition = false,
  hasBoxShadow = false,
  children
}) => {
  return (
    <div
      className={[
        styles.alert,
        hasTransition ? styles.transitionOn : "",
        hasBoxShadow ? styles.boxShadow : "",
        styles[`alert-${alertEdge}`],
        styles[`alert-${alertColor}`],
        styles[`alert-text-${alertTextColor}`],
        styles[`alert-${alertSize}`],
        styles[`alert-text-${alertTextWeight}`]
      ].join(" ")}
      style={{
        gap: "1rem"
      }}
    >
      {children}
    </div>
  );
};
