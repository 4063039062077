import Language from "../../Interfaces/Language";

export const tr: Language = {
  common: {
    help: "Yardıma mı ihtiyacınız var?",
    helpCenter: "Yardım Merkezi",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "Bu testlerden ne beklenmelidir",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Sıkça Sorulan Sorular",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7144514-sikca-sorulan-sorular",
    copyright: "Telif Hakkı",
    copyrightNotices: "Telif Hakkı Bildirimleri",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6815627-telif-hakki-bildirimi",
    terms: "Kullanım Şartları",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6972002-kullanim-hukum-ve-kosullari",
    privacy: "Gizlilik Bildirimi",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971995-gizlilik-politikasi",
    dmca: "DMCA Politikası",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857155-dijital-milenyum-telif-hakki-yasasi-politikasi",
    accommodations: "Engellilik / Erişilebilirlik",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6971993-engellilik-erisilebilirlik",
    technicalSupport: "Teknik Destek",
    chatSupport:
      "Destek için lütfen bu sayfanın sağ alt köşesinden ekibimizle sohbet edin"
  },
  instruction: {
    eppCopyright:
      "This assessment includes material reproduced from SalesAP ©1995 and CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Değerlendirmeniz duraklatıldı.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Test devam ederken lütfen tarayıcı pencerenizden <b>çıkmayın</b> veya değerlendirme alanının dışına tıklamayın.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "İnternet bağlantı sorunları yaşıyorsanız, sorun giderme ipuçları için ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "buraya tıklayın",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2: ".",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Değerlendirmeden çıkmanız, test güvenliği sebebiyle <b>hesabınızın kilitlenmesine</b> yol açabilir ve bu da değerlendirmenizi tamamlamanızı engelleyebilir.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Değerlendirmeden <b>2 kez daha</b> çıkarsanız test güvenliği sebebiyle <b>hesabınız kilitlenecektir</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Değerlendirmeden tekrar çıkarsanız test güvenliği sebebiyle hesabınız kilitlenecektir.</b>",
    BUTTON_RESUME: "Devam Et"
  },
  error: {
    common: {
      UH_OH: "Ah!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Ağ sorunları nedeniyle yüklenemiyor. Lütfen internet bağlantınızı kontrol edin ve sayfayı birkaç dakika içinde yenileyin.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "Sorun devam ederse lütfen şuraya bakın: ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "destek SSS’leri",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7144514-sikca-sorulan-sorular"
    }
  }
};
