import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import TestMakerTest from "../../Tests/TestDisplays/TestMakerTest";
import { getTestMakerData } from "../../utils/redisHelpers";
import ExpiredTimeError from "../utility/error/expired-time/ExpiredTimeError";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const TestMaker: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getTestMakerData>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  if (data.expiredTime > props.timeAllowed) {
    return <ExpiredTimeError message={props.translatedText.expiredTimeError} />;
  }

  return (
    <TestMakerTest
      {...props}
      testMakerTestId={Number(String(props.subTestId).replace("tm_", ""))}
      timeRemaining={props.timeAllowed - data.expiredTime}
      startingQuestion={data.questionNumber}
      answeredArray={data.answeredQuestions}
    />
  );
};

export default TestMaker;
