import React, { FunctionComponent, useContext } from "react";
import { Lockout } from "../../../../Interfaces/Lockout";
import { LanguageContext } from "../../../../Providers/LanguageProvider";
import FormattedMessage from "../../../utility/message/FormattedMessage";
import { LOCKOUT_LAST_CHANCE_THRESHOLD } from "../LockoutMessage";

export const LockoutAlertMessageFinalParagraph: FunctionComponent<{
  lockout: Lockout;
}> = ({ lockout }) => {
  const language = useContext(LanguageContext)["lockout"];
  const isAtLimit = lockout.lockout >= LOCKOUT_LAST_CHANCE_THRESHOLD;
  return isAtLimit ? (
    <FormattedMessage
      message={language.LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT}
    />
  ) : (
    <FormattedMessage
      message={language.LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH}
    />
  );
};
