import Language from "../../Interfaces/Language";

export const da: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink: "/home/faq/au",
    copyright: "Copyright",
    copyrightNotices: "Copyright Notices",
    copyrightNoticesLink: "/home/copyright",
    terms: "Terms of Use",
    termsLink: "/home/terms",
    privacy: "Privacy Statement",
    privacyLink: "/home/privacy",
    dmca: "DMCA Policy",
    dmcaLink: "/home/dmca",
    accommodations: "Disability / Accommodations",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6079734-disability-accommodations",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Denne vurdering inkluderer materiale reproduceret fra SalesAP ©1995 og CSAP ©2002, Multi-Health Systems Inc. Alle rettigheder forbeholdes.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Your Assessment Has Been Paused.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Please <b>do not</b> leave your browser window or click outside of the assessment area while the test is in progress.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "If you are experiencing internet connectivity issues, please ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "click here",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " for some troubleshooting tips.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Leaving the assessment will cause you to be <b>locked out</b> for test security reasons, which may prevent you from completing your assessment. ",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Please note that if you leave the assessment 2 more times, you will be <b>locked out</b> for test security reasons. ",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>If you leave the assessment again, you will be locked out for test security reasons.</b>",
    BUTTON_RESUME: "Resume"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://www.criteriacorp.com.au/candidates/candidate-faqs"
    }
  }
};
