import { useEffect, useState } from "react";
import { Hook } from "../utils/Reference/Hook";

type VisibilityResult = {
  isHidden: boolean;
  refocus: () => void;
};

const useVisibility: Hook<void, VisibilityResult> = () => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.visibilityState === "hidden") {
        setIsHidden(true);
      }
    };
    const focusLossHandler = () => {
      setIsHidden(true);
    };
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    document.addEventListener("blur", focusLossHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
      document.removeEventListener("blur", focusLossHandler);
    };
  }, []);

  return {
    isHidden,
    refocus: () => setIsHidden(false)
  };
};

export default useVisibility;
