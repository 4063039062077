import { createContext, FunctionComponent, useContext } from "react";
import Language from "../Interfaces/Language";
import { en_us } from "../Resources/languages";
import { subTestLocaleMap } from "../utils/Reference/SubTestDataMaps";
import { setLangFile } from "../utils/translationHelpers";
import { TokenContext } from "./TokenProvider";

export const LanguageContext = createContext<Language>(en_us);

const LanguageProvider: FunctionComponent = ({ children }) => {
  const { subTestId } = useContext(TokenContext);
  const languageFile = setLangFile(subTestLocaleMap[subTestId]);
  return (
    <LanguageContext.Provider value={languageFile}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
