import Language from "../../Interfaces/Language";

export const es_la: Language = {
  common: {
    help: "¿Necesita ayuda?",
    helpCenter: "Servicio de ayuda",
    expect: "¿Cómo son las pruebas?",
    faq: "Preguntas frecuentes",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869773-preguntas-frecuentes",
    copyright: "Derechos de autor",
    copyrightNotices: "Avisos de derechos de autor",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994360-aviso-de-derechos-de-autor",
    terms: "Términos de uso",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869769-terminos-de-uso",
    privacy: "Declaración de privacidad",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869768-politica-de-privacidad",
    dmca: "Política DMCA",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869777-politica-dmca",
    accommodations: "Discapacidad/Adaptaciones",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6989970-discapacidad-adaptaciones",
    technicalSupport: "Soporte técnico",
    chatSupport:
      "Si tiene alguna pregunta, chatee con nuestro equipo en la parte inferior derecha de esta página."
  },
  instruction: {
    eppCopyright:
      "Esta evaluación incluye material reproducido de SalesAP ©1995 y CSAP ©2002, Multi-Health Systems Inc. Todos los derechos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Copyright de WTMA © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Su evaluación ha sido pausada.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Por favor, <b>no</b> salga de la ventana de su navegador ni haga clic fuera del área de la evaluación mientras la completa.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Si tiene algún problema con la conexión a internet, haga ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "clic aquí",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " para obtener algunos consejos para solucionarlo.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Abandonar la evaluación hará que quede <b>bloqueada</b> por motivos de seguridad relacionados con la prueba, lo que puede impedirle completar la evaluación.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Tenga en cuenta que, si abandona la evaluación dos veces más, quedará <b>bloqueada</b> por motivos de seguridad relacionados con la prueba.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Si vuelve a salir de la evaluación, no podrá volver a acceder por motivos de seguridad de la prueba.</b>",
    BUTTON_RESUME: "Reanudar"
  },
  error: {
    common: {
      UH_OH: "¡Vaya!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "No se pudo cargar la página por problemas en la red. Compruebe su conexión a Internet y actualice la página en unos instantes.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "Si el problema persiste, consulte las ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "preguntas frecuentes sobre asistencia",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869773-preguntas-frecuentes"
    }
  }
};
