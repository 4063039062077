import React, { FunctionComponent, useContext } from "react";
import { LanguageContext } from "../../../../Providers/LanguageProvider";
import NetworkErrorIcon from "../../../icons/network-error";

import styles from "./NetworkError.module.css";

const NetworkError: FunctionComponent = (): JSX.Element => {
  const language = useContext(LanguageContext)["error"];
  return (
    <article className={styles.container}>
      <section className={styles.content}>
        <NetworkErrorIcon />
        <h1 className={styles["top-text"]}>{language.common.UH_OH}</h1>
        <p className={styles["bottom-text"]}>
          {language.network.MESSAGE_PARAGRAPH_1}
        </p>
        <p className={styles["bottom-text"]}>
          {language.network.MESSAGE_PARAGRAPH_2_SECTION_1}
          <a href={language.network.MESSAGE_PARAGRAPH_2_LINK}>
            {language.network.MESSAGE_PARAGRAPH_2_LINK_TEXT}
          </a>
          {language.network.MESSAGE_PARAGRAPH_2_SECTION_2}
        </p>
      </section>
    </article>
  );
};

export default NetworkError;
