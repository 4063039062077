/* eslint-disable array-callback-return */
import {
  Location,
  Redirect,
  // globalHistory
  Router,
  navigate
} from "@reach/router";
import React, { Component, lazy } from "react";
import {
  isMobile
  // isTablet,
  // isIPad13
} from "react-device-detect";
import Applications from "../Applications/Applications";
import AttorneyVerificationForm from "../Components/AttorneyVerificationForm";
import CandidateRequestForm from "../Components/CandidateRequestForm";
import CandidateVerificationForm from "../Components/CandidateVerificationForm";
import Content from "../Components/Content";
import CustomerResponseForm from "../Components/CustomerResponseForm";
import ErrorPage from "../Components/ErrorPage";
import Event from "../Components/Event";
import NoTests from "../Components/NoTests";
import ReuseResults from "../Components/ReuseResults";
import { TestEventProvider } from "../Contexts/TestEventContext";
import DesktopOnly from "../Forms/DesktopOnly";
import DocumentUpload from "../Forms/DocumentUpload";
import EventId from "../Forms/EventId";
import EventIdDebug from "../Forms/EventIdDebug";
import EventIdDebugResult from "../Forms/EventIdDebugResult";
import EventIdForm from "../Forms/EventIdForm";
import InsightsReport from "../Forms/InsightsReport";
import { LinkResults } from "../Forms/LinkResults/LinkResults";
import { LinkResultsCheck } from "../Forms/LinkResults/LinkResultsCheck";
import { LinkResultsSuccess } from "../Forms/LinkResults/LinkResultsSuccess";
import { LinkResultsVerify } from "../Forms/LinkResults/LinkResultsVerify";
import Overview from "../Forms/Overview";
import UnsupportedBrowser from "../Forms/UnsupportedBrowser";
import VerifyForm from "../Forms/VerifyForm";
import LandingPageParameters from "../Interfaces/LandingPageParameters";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import { Lockout } from "../Interfaces/Lockout";
import { RevelianTestData } from "../Interfaces/RevelianTestData";
import TestTakerData from "../Interfaces/TestTakerData";
import RevelianTest from "../Tests/RevelianTest";
import VideoInterviewTestEventCompleted from "../Tests/VideoInterviewTestEventCompleted";
import { apiURL, sskowk } from "../utils/constants";
import Encryption from "../utils/Encryption";
import { getResumeTestDataFetchMethod } from "../utils/redisHelpers";
import {
  getAllTests,
  personalityTests
} from "../utils/Reference/SubTestDataMaps";
import {
  getRedirectUrlForExitPage,
  getReusedResultsCaseWithDecryptedData,
  getReusedResultsCaseWithRawData
} from "../utils/shared";
import { telemetryHandler } from "../utils/telemetry";
import WorkplaceInsights from "../WorkplaceInsights/WorkplaceInsights";
import WorkplaceInsightsPersonify from "../WorkplaceInsightsPersonify/WorkplaceInsightsPersonify";
import {
  getInvalidEventErrorMessage,
  getStateObjectToUpdate,
  setUpConfigCat
} from "./helper";

const Test = lazy(() => import("../Tests/Test"));

interface State {
  testIndex: number;
  eventId: string;
  jobCode: string;
  token: string;
  testEventId: number | null;
  testEventData: any;
  applicationIdsArray: string[];
  testIdsArray: string[];
  completedSubTestIds: string[];
  isResumingTest: boolean;
  usedEventId: boolean;
  testIdToResume: string | null;
  updateIsResumingTest: (value: boolean, testIdToResume: string | null) => void;
  expiredTime: number;
  eventIdCompleted: boolean;
  retrievingData: boolean;
  invalidEventId: boolean;
  errorMessage: string;
  content: string;
  invalidTestTakerMessage: string;
  restartCounter: number;
  reachedRestartLimit: boolean;
  invalidJobCode: boolean;
  invalidJobCodeMessage: string;
  u: string | string[] | null;
  first: string | string[] | null;
  last: string | string[] | null;
  email: string | string[] | null;
  orderId: string | null;
  source: string | null;
  optionalReturnUrl: string | null;
  isMobile: boolean;
  sameTest: boolean;
  updateSameTest: (value: boolean) => void;
  updateRetrievingData: (value: boolean) => void;
  updateExpiredTime: (value: number) => void;
  sendTestTakerData: (
    url: string,
    method: string,
    data: TestTakerData
  ) => Promise<boolean>;
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => LandingPageStyle;
  updateCompletedApplications: (applicationId: number) => void;
  updateResumeSubmitted: (value: number) => void;
  updateOtherDocsSubmitted: (documentId: string, value: boolean) => void;
  landingPageParameters: {
    logoPlacement: string;
    welcomeMessage: string;
    welcomeMessagePlacement: string;
    instruction: string;
    backgroundColor: string;
    textColor: string;
    welcomeColor: string;
    formPlacement: string;
    formBorderColor: string;
    formBackgroundColor: string;
    landingPageVideo: string;
  };
  showTestCompleted: number;
  fromEventIdLink: boolean;
  updateUsedEventId: (value: boolean) => void;
  testIdOfFirstTest: string | null;
  eventExpirationDate: string | null;
  eventInvitationTimeZone: string | null;
  overviewExpiredMessage: string | null;
  updateExpiredMessage: () => void;
  expiredTimeErrorMessage: string | null;
  updateWrongExpiredTimeMessage: () => void;
  revelianTestData: RevelianTestData | null;
  userDisqualified: boolean;
  isAU: boolean;
  referrer: {
    id: number | null;
    url: string;
  };
  lastQuestionNumber: number;
  updateLastQuestionNumber: (value: number) => void;
  configCatObject: any;
  companyAccountId: string;
  partnerAccountId: string | null;
  videoInterview: any;
  isVideoInterviewDiscoveryJob: boolean;
  videoInterviewDiscovery: {
    evaluationLink: string;
    invitationMsg: string;
    invitationDescription: string;
    welcome: string;
    completionMsg: string;
    reviewSampleCandidates: string;
    beginEvaluation: string;
  } | null;
  isVideoInterviewDiscoveryJobCompleted: boolean;
  updateTestEventData: (value: any) => void;
  lockout: Lockout;
}

declare var window: any;

class App extends Component<{}, State> {
  UNSAFE_componentWillMount() {
    const appElement = document.getElementById("app-body");
    if (appElement) {
      appElement.style.backgroundColor =
        this.state.landingPageParameters.formBackgroundColor;
    }
    window.addEventListener("beforeunload", this.onUnmount, false);
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: "o78exqrb"
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    telemetryHandler(this.state);
  }

  onUnmount = () => {};

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnmount, false);
    this.onUnmount();
    this.setState({
      testIndex: 0,
      eventId: "",
      jobCode: "",
      testEventData: {},
      token: "",
      applicationIdsArray: [] as any[],
      testEventId: null,
      testIdsArray: [],
      isResumingTest: false,
      usedEventId: false,
      testIdToResume: null,
      expiredTime: 0,
      eventIdCompleted: false,
      sendTestTakerData: this.sendTestTakerData,
      generateLandingPageStyle: this.generateLandingPageStyle,
      updateCompletedApplications: this.updateCompletedApplications,
      updateResumeSubmitted: this.updateResumeSubmitted,
      updateOtherDocsSubmitted: this.updateOtherDocsSubmitted,
      updateSameTest: this.updateSameTest,
      updateExpiredTime: this.updateExpiredTime,
      updateIsResumingTest: this.updateIsResumingTest,
      retrievingData: false,
      invalidEventId: false,
      invalidTestTakerMessage: "",
      invalidJobCode: false,
      invalidJobCodeMessage: "",
      errorMessage: "",
      content: "",
      restartCounter: 0,
      u: null,
      first: null,
      last: null,
      email: null,
      orderId: null,
      source: null,
      optionalReturnUrl: null,
      isMobile: false,
      completedSubTestIds: [],
      sameTest: false,
      landingPageParameters: {
        logoPlacement: "Left",
        welcomeMessage: "Assessment Center",
        welcomeMessagePlacement: "Center",
        instruction: "",
        backgroundColor: "#FFF",
        textColor: "",
        welcomeColor: "",
        formPlacement: "Center",
        formBorderColor: "#E3E3E3",
        formBackgroundColor: "#F5F5F5",
        landingPageVideo: ""
      },
      showTestCompleted: 0, // 0 -> Don't show test completed page, 1 -> Show test completed page, 2 -> Show test completed page + reused results message, 3 -> Show custom exit page instead of test completed
      fromEventIdLink: false,
      updateUsedEventId: this.updateUsedEventId,
      testIdOfFirstTest: null,
      eventExpirationDate: null,
      eventInvitationTimeZone: null,
      overviewExpiredMessage: null,
      updateExpiredMessage: this.updateExpiredMessage,
      expiredTimeErrorMessage: null,
      updateWrongExpiredTimeMessage: this.updateWrongExpiredTimeMessage,
      revelianTestData: null,
      isAU: false,
      lastQuestionNumber: 0,
      updateLastQuestionNumber: this.updateLastQuestionNumber
    });
  }

  /* ---------- Fetch API methods ---------- */

  fetchTestEventByEventId = async (
    eventId: string,
    fromLink: boolean,
    fromExternalTest: boolean,
    isADSAssessment?: boolean
  ): Promise<boolean> => {
    if (fromLink) {
      this.setState({ fromEventIdLink: true });
    }
    this.setState({
      retrievingData: true,
      usedEventId: isADSAssessment ? false : true
    });
    try {
      const response = await fetch(`${apiURL}/event/${eventId}`);
      const data = await response.json();

      // test data comes in as an encrypted string, so we need to decrypt the string, parse it and put in to state.
      const encryption = new Encryption();
      const decryptedData = data.data
        ? JSON.parse(encryption.decrypt(data.data, sskowk))
        : null;

      this.setState({ isAU: decryptedData?.locale === "AU" });
      // check if the test is being taken on a mobile device and upgrade accordingly.
      this.updateIsMobile(isMobile);
      if (decryptedData && Object.keys(decryptedData).includes("invalid")) {
        this.setState(
          {
            invalidJobCode: true,
            invalidJobCodeMessage: getInvalidEventErrorMessage(
              "eventId",
              decryptedData?.locale === "AU",
              decryptedData?.invalidEventIdMessage // api uses 'EventId' as the identifier because the candidates access with the event Id of the inactive account
            ),
            retrievingData: false
          },
          () => {
            navigate("/");
          }
        );
      } else {
        const showReusePage = this.hasCompletedTestBefore(data, decryptedData);

        this.setState({ retrievingData: false });
        // if the returned object includes the `isVideoInterviewDiscoveryJobCompleted` field
        // redirect to the VI event complete page with the VI Discovery content
        if (
          decryptedData &&
          Object.keys(decryptedData).includes(
            "isVideoInterviewDiscoveryJobCompleted"
          )
        ) {
          this.setState({
            isVideoInterviewDiscoveryJobCompleted: true,
            isVideoInterviewDiscoveryJob:
              decryptedData.isVideoInterviewDiscoveryJob,
            videoInterviewDiscovery: decryptedData.videoInterviewDiscovery
          });
          navigate(`/video-interview-complete/${eventId}`);
        } else if (showReusePage !== 0) {
          // if the returned object is an error, display the error message, if it has an exit page object
          // redirect to the appropriate url. otherwise, just continue with the event.
          this.setState({
            showTestCompleted: showReusePage
          });
          navigate("/event-complete");
        } else if (data?.eventStatus === "completed") {
          this.setState({
            showTestCompleted: 1
          });
          navigate("/event-complete");
        } else if (data.error) {
          this.setState({
            invalidEventId: true,
            errorMessage: data.error
          });
        } else {
          if (
            this.state.revelianTestData === null &&
            decryptedData.hasOwnProperty("revelian")
          ) {
            this.setState({ revelianTestData: decryptedData.revelian });
          }

          // create an array of test Ids that have not been completed yet
          const testIdsArray = decryptedData.tests
            ? this.getTestIdsArray(decryptedData.tests)
            : [];

          // set up restartCounter
          if (
            decryptedData.eventStarted === 1 &&
            this.state.testIdOfFirstTest &&
            personalityTests.includes(this.state.testIdOfFirstTest)
          ) {
            this.setState({ restartCounter: 0 });
          } else if (decryptedData.eventStarted > 0) {
            this.setState({ restartCounter: decryptedData.eventStarted });
          }

          // create an array of application Ids that need to be completed
          const applicationIdsArray: string[] | void = decryptedData.application
            ? this.getApplicationIdsArray(decryptedData.application)
            : [];

          if (decryptedData.videoInterview) {
            this.setState({ videoInterview: decryptedData.videoInterview });
          }

          if (
            decryptedData.isVideoInterviewDiscoveryJob &&
            decryptedData.videoInterviewDiscovery
          ) {
            this.setState({
              isVideoInterviewDiscoveryJob:
                decryptedData.isVideoInterviewDiscoveryJob,
              videoInterviewDiscovery: decryptedData.videoInterviewDiscovery
            });
          }

          /*
           * if there is nothing else required and no tests left to do, complete the event.
           * otherwise, proceed normally through the workflow.
           * it is set up this way so that events that have been completed, but for some reason did not call
           * the endTestEvent method correctly, once the Event ID is resubmitted, it will then call it
           * correctly.
           */
          if (fromExternalTest) {
            this.setState(
              {
                eventId,
                testEventId: decryptedData.testEventId,
                token: decryptedData.token,
                testEventData: decryptedData,
                testIdsArray: testIdsArray,
                applicationIdsArray
              },
              () => {
                navigate(`/overview`);
              }
            );
          } else if (
            testIdsArray.length === 0 &&
            applicationIdsArray.length === 0 &&
            !decryptedData.requireResume &&
            !decryptedData.requireLinkedIn &&
            !decryptedData.documents &&
            this.state.videoInterview === null
          ) {
            this.setState(
              {
                eventIdCompleted: true,
                eventId,
                testEventId: decryptedData.testEventId,
                token: decryptedData.token,
                testEventData: decryptedData,
                applicationIdsArray,
                eventExpirationDate: decryptedData.eventExpirationDateUtc,
                eventInvitationTimeZone: decryptedData.eventInvitationTimeZone
              },
              () => navigate("/tests")
            );
          } else if (
            decryptedData &&
            testIdsArray.length === 0 &&
            applicationIdsArray.length > 0 &&
            this.state.videoInterview !== null
          ) {
            this.setState(
              {
                eventId,
                testEventId: decryptedData.testEventId,
                token: decryptedData.token,
                testEventData: decryptedData,
                applicationIdsArray,
                eventExpirationDate: decryptedData.eventExpirationDateUtc,
                eventInvitationTimeZone: decryptedData.eventInvitationTimeZone,
                videoInterview: decryptedData.videoInterview
              },
              () => {
                navigate(`/verify`);
              }
            );
          }
          // Determine test starting point and navigate to the appropriate place
          else if (
            decryptedData &&
            (testIdsArray.length > 0 || applicationIdsArray.length > 0)
          ) {
            this.setState({
              eventExpirationDate: decryptedData.eventExpirationDateUtc,
              eventInvitationTimeZone: decryptedData.eventInvitationTimeZone
            });
            this.navigateToStartOfTest(
              decryptedData,
              testIdsArray,
              eventId,
              applicationIdsArray
            );
          } else {
            this.setState(
              {
                eventId,
                testEventId: decryptedData.testEventId,
                token: decryptedData.token,
                testEventData: decryptedData,
                applicationIdsArray,
                eventExpirationDate: decryptedData.eventExpirationDateUtc,
                eventInvitationTimeZone: decryptedData.eventInvitationTimeZone
              },
              () => {
                navigate(`/verify`);
              }
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  fetchTestEventByJobCode = async (
    jobCode: string,
    userAccountId?: string
  ): Promise<boolean> => {
    this.setState({ retrievingData: true });
    /* capture Referrer data and send it to the api */
    if (userAccountId && jobCode) {
      const referrer = document.referrer;
      const formData = {
        userAccountId: userAccountId,
        jobCode: jobCode,
        referrer: referrer
      };
      const response = await fetch(`${apiURL}/linkData`, {
        method: "POST",
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (data.id) {
        this.setState({ referrer: { id: data.id, url: referrer } });
      }
    }
    const url =
      userAccountId !== undefined
        ? `${apiURL}/eventDetails/${jobCode}/${userAccountId}`
        : `${apiURL}/eventDetails/${jobCode}`;
    if (Object.keys(this.state.testEventData).length === 0) {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (!data.error) {
          // test data comes in as an encrypted string, so we need to decrypt the string, parse it and put in to state.
          const encryption = new Encryption();
          const decryptedData = JSON.parse(
            encryption.decrypt(data.data, sskowk)
          );
          this.setState({ isAU: decryptedData?.locale === "AU" });

          /*
           * if the link is from a suspended or deactivated account, the API will return an object with
           * three properties: test, equal to null, invalid equal to true, and landingPage, equal to the default
           * landing page styles. this allows us to redirect the candidate to the verify form, instead of erroring out.
           */

          if (Object.keys(decryptedData).includes("invalid")) {
            this.setState(
              {
                invalidJobCode: true,
                invalidJobCodeMessage: getInvalidEventErrorMessage(
                  "link",
                  decryptedData?.locale === "AU",
                  decryptedData?.invalidJobLinkMessage
                ),
                retrievingData: false
              },
              () => {
                navigate("/");
              }
            );
          } else {
            this.setState({ retrievingData: false });

            // create an array of application Ids that need to be completed
            const applicationIdsArray: string[] | void =
              decryptedData.application
                ? this.getApplicationIdsArray(decryptedData.application)
                : [];

            // set the body to the color of the landing page, so the entire page is that color, and not
            // just the container.
            const landingPageValue = decryptedData.landingPage;
            const appElement = document.getElementById("app-body");
            if (appElement) {
              appElement.style.backgroundColor =
                landingPageValue.formBackgroundColor;
            }

            this.setState(
              {
                jobCode,
                testEventData: decryptedData,
                isAU: decryptedData?.locale === "AU",
                applicationIdsArray,
                landingPageParameters: landingPageValue
              },
              () => {
                navigate(
                  `/link/index/${jobCode}?${
                    this.state.u ? `u=${this.state.u}` : ""
                  }${this.state.source ? `&source=${this.state.source}` : ""}${
                    this.state.first ? `&first=${this.state.first}` : ""
                  }${this.state.last ? `&last=${this.state.last}` : ""}${
                    this.state.email ? `&email=${this.state.email}` : ""
                  }${
                    this.state.orderId ? `&orderId=${this.state.orderId}` : ""
                  }${
                    this.state.optionalReturnUrl
                      ? `&returnUrl=${this.state.optionalReturnUrl}`
                      : ""
                  }`
                );
              }
            );
          }
        } else {
          this.setState(
            {
              invalidJobCode: true,
              invalidJobCodeMessage: data.error,
              retrievingData: false
            },
            () => {
              navigate("/");
            }
          );
        }
      } catch (error) {
        console.log(
          "There was an error fetching the test event by job code: ",
          error
        );
      }
    }
    return true;
  };

  fetchTestEventByTestBattery = async (
    testBatteryId: string,
    userAccountId: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(
        `${apiURL}/encoded/${testBatteryId}/${userAccountId}`
      );
      const data = await response.json();

      // test data comes in as an encrypted string, so we need to decrypt the string, parse it and put in to state.
      const encryption = new Encryption();
      const decryptedData = JSON.parse(encryption.decrypt(data.data, sskowk));
      this.setState({ isAU: decryptedData?.locale === "AU" });

      if (Object.keys(decryptedData).includes("invalid")) {
        this.setState(
          {
            invalidJobCode: true,
            invalidJobCodeMessage: getInvalidEventErrorMessage(
              "link",
              decryptedData?.locale === "AU",
              decryptedData?.invalidJobLinkMessage
            ),
            retrievingData: false
          },
          () => {
            navigate("/");
          }
        );
      } else {
        // create an array of application Ids that need to be completed
        const applicationIdsArray: string[] | void = decryptedData.application
          ? this.getApplicationIdsArray(decryptedData.application)
          : [];

        this.setState(
          {
            testEventData: decryptedData,
            isAU: decryptedData?.locale === "AU",
            applicationIdsArray
          },
          () => {
            navigate("/verify");
          }
        );
      }
    } catch (error) {
      console.log(
        "There was an error fetching the test event by test battery: ",
        error
      );
    }
    return true;
  };

  updateConfigCatObject = (configCatObject: any) => {
    this.setState({ configCatObject: configCatObject });
    // const showNewEventIdPage =
    //   this.state.testEventData.translatedText.language === "english" &&
    //   configCatObject.isABTestEventIdEnabled;

    // const emailEventIdPageVersion = showNewEventIdPage ? 2 : 1;
  };

  sendTestTakerData = async (
    url: string,
    method: string,
    testTakerData: TestTakerData,
    referrer?: {
      id: number | null;
      url: string;
    }
  ): Promise<boolean> => {
    try {
      // initialize testIdsArray variable at top of scope
      let testIdsArray: string[] = [];
      let modifiedTestTakerData = testTakerData;
      if (method === "PUT") {
        modifiedTestTakerData = {
          ...testTakerData,
          testEventId: this.state.testEventId
        };
      }
      const response = await fetch(`${apiURL}${url}`, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.token
        },
        body: JSON.stringify(modifiedTestTakerData)
      });
      const responseData = await response.json();

      // check to see if this is a crossover link. if so, show them an error page.
      if (referrer?.url.includes("crossoverccat")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await fetch(`${apiURL}/linkData/${referrer.id}`, {
          method: "PUT",
          body: JSON.stringify({ testEventId: responseData.testEventId })
        });

        navigate("/crossover-error");
        return false;
      }

      // if a manually entered event id or an event id link is used, we follow this flow.
      if (this.state.eventId !== "") {
        // for events that have come in via event id link or manually entering the eventId, we need
        // to setup the configcat object here.
        setUpConfigCat(
          this.state.testEventData,
          testTakerData.testTakerState,
          this.state.isAU,
          isMobile,
          this.updateConfigCatObject
        );

        this.setState({ retrievingData: false });

        if (
          this.state.testEventData.hideEventIdScreen === 1 &&
          this.state.testEventData.eventStarted === 0 &&
          !this.state.isResumingTest
        ) {
          // if the company has decided to skip showing the event ID screen and the candidate is
          // starting the test event for the first time, we need to call updateDateEventStarted
          this.updateDateEventStarted(
            this.state.testEventData.testTaker.testTakerId,
            this.state.testEventData.testEventId,
            this.state.token
          );
        }

        if (
          this.state.testEventData.showCandidateFacingReport &&
          this.state.testEventData.tests !== null
        ) {
          // we check here to determine if the job the link comes from has showCandidateFacing Report
          // turned on, and whether there are tests in the event, and if so, show the WIR screen
          navigate("/insights-report");
        } else if (this.state.testEventData.hideEventIdScreen === 1) {
          // if the company has decided to skip showing the event ID screen, we navigate directly to overview
          navigate("/overview");
        } else {
          navigate("/event_id");
        }
      } else {
        if (responseData.error) {
          this.setState({ retrievingData: false });
          this.setState({ invalidTestTakerMessage: responseData.error });
        }
        if (responseData && !responseData.error) {
          // If the api found a duplicate user for this job link, then it will return the encrypted big object
          // That object can be used, rather than fetching by the newly created Event ID
          let eventIdData;
          if (!responseData.foundDuplicate) {
            const eventIdResponse = await fetch(
              `${apiURL}/event/${responseData.eventId}`
            );
            eventIdData = await eventIdResponse.json();
          } else {
            eventIdData = responseData;
          }

          // if the returned object is an error object, specifically reused results, show the reused results page
          // the number 2 code refers to the TestAlreadyCompleted component with the reused results page UI
          // finally, navigate to the event complete page to show the TestAlreadyCompleted component
          if (eventIdData.error === "reused result") {
            this.setState({
              retrievingData: false,
              showTestCompleted: 2
            });

            navigate("/event-complete");
          }

          // test data comes in as an encrypted string, so we need to decrypt the string, parse it and put in to state.
          const encryption = new Encryption();
          const decryptedData = JSON.parse(
            encryption.decrypt(eventIdData.data, sskowk)
          );

          // if noMoreTests is in the decrypted event Id data, show the reused results page
          // the flag is used if the sqs event takes longer than the successful event creation
          // the number 2 code refers to the TestAlreadyCompleted component with the reused results page UI
          // finally, navigate to the event complete page to show the TestAlreadyCompleted component
          if (decryptedData.noMoreTest) {
            this.setState({
              retrievingData: false,
              showTestCompleted: 2
            });

            navigate("/event-complete");
          } else {
            // call updateDateEventStarted if the candidate is starting test event for the first time
            if (
              decryptedData.eventStarted === 0 &&
              !responseData.foundDuplicate
            ) {
              this.updateDateEventStarted(
                decryptedData.testTaker.testTakerId,
                decryptedData.testEventId,
                decryptedData.token
              );
            }
            // append the testTaker object that is returned from the event endpoint to the state, and therefore the context
            const testEventDataCopy = this.state.testEventData;
            testEventDataCopy.testTaker = decryptedData.testTaker;
            testEventDataCopy.tests = decryptedData.tests;
            this.setState({
              testEventData: testEventDataCopy,
              testEventId: decryptedData.testEventId,
              eventId: decryptedData.eventId,
              token: decryptedData.token,
              eventExpirationDate: decryptedData.eventExpirationDateUtc,
              eventInvitationTimeZone: decryptedData.eventInvitationTimeZone,
              companyAccountId: decryptedData.companyAccountId,
              partnerAccountId: decryptedData.partnerAccountId
                ? decryptedData.partnerAccountId
                : null,
              videoInterview: decryptedData.videoInterview
            });

            setUpConfigCat(
              decryptedData,
              testTakerData.testTakerState,
              this.state.isAU,
              isMobile,
              this.updateConfigCatObject
            );

            // append any revelian data, if present, to the state, and therefore the context
            if (responseData.hasOwnProperty("revelian")) {
              this.setState({
                revelianTestData: responseData.revelian
              });
            }

            // create an array of test Ids that have not been completed yet
            if (decryptedData.tests) {
              testIdsArray = this.getTestIdsArray(decryptedData.tests);
            } else {
              this.setState({
                eventIdCompleted: true
              });
            }
            if (testIdsArray.length > 0) {
              this.setState({
                testEventId: decryptedData.testEventId,
                token: decryptedData.token,
                eventId: decryptedData.eventId,
                testIdsArray
              });
            }

            this.setState({ retrievingData: false });

            // we check here to determine if the job the link comes from has showCandidateFacing Report
            // turned on, and whether there are tests in the event, and if so, show the WIR screen
            if (
              decryptedData.showCandidateFacingReport &&
              decryptedData.tests !== null
            ) {
              navigate("/insights-report");
            } else if (decryptedData.hideEventIdScreen === 1) {
              // if the company has decided to skip showing the event ID screen, we navigate directly to overview
              navigate("/overview");
            } else {
              navigate("/event_id");
            }
          }
        }
      }
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
    return true;
  };

  fetchContent = async (
    contentType: string,
    locale?: string
  ): Promise<boolean> => {
    try {
      let fetchURL = "";
      if (locale !== undefined) {
        fetchURL =
          this.state.testEventData.size > 0
            ? `${apiURL}/content/${encodeURI(contentType)}/${
                this.state.testEventData.translatedText.language
              }`
            : `${apiURL}/content/${encodeURI(contentType)}/english/${locale}`;
      } else {
        fetchURL =
          this.state.testEventData.size > 0
            ? `${apiURL}/content/${encodeURI(contentType)}/${
                this.state.testEventData.translatedText.language
              }`
            : `${apiURL}/content/${encodeURI(contentType)}/english`;
      }
      const response = await fetch(fetchURL);
      const data = await response.json();

      this.setState({
        content: data.content
      });
    } catch (error) {
      this.setState({
        content: "The content could not be loaded. Please refresh to try again."
      });
    }
    return true;
  };

  updateDateEventStarted = async (
    testTakerId: string,
    testEventId: string,
    token: string
  ): Promise<boolean> => {
    try {
      await fetch(`${apiURL}/updateEventDate/${testEventId}`, {
        method: "PUT",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ testTakerId })
      });
    } catch (error) {}
    return true;
  };

  /* ---------- Utility Functions ---------- */

  generateLandingPageStyle = (
    landingPageParameters: LandingPageParameters
  ): LandingPageStyle => {
    if (landingPageParameters) {
      return {
        backgroundColor: landingPageParameters.backgroundColor,
        formBackgroundColor: landingPageParameters.formBackgroundColor,
        formBorderColor: landingPageParameters.formBorderColor,
        logoPlacement: landingPageParameters.logoPlacement,
        textColor: landingPageParameters.textColor,
        welcomeColor: landingPageParameters.welcomeColor,
        welcomeMessagePlacement: landingPageParameters.welcomeMessagePlacement,
        welcomeMessage: landingPageParameters.welcomeMessage,
        instructions: landingPageParameters.instructions,
        landingPageVideo: landingPageParameters.landingPageVideo
      };
    }
    return {
      logoPlacement: "Left",
      welcomeMessagePlacement: "Center",
      backgroundColor: "#FFF",
      textColor: "",
      formBorderColor: "#E3E3E3",
      formBackgroundColor: "#F5F5F5",
      welcomeColor: ""
    };
  };

  getTestIdsArray = (tests: any) => {
    const sortedArray: string[] = [];
    const testIdsArray: string[] = [];
    Object.keys(tests)
      .sort((a, b) => tests[a].testOrder - tests[b].testOrder)
      .map(subTestId => {
        sortedArray.push(subTestId);
        if (!tests[subTestId].completed) {
          testIdsArray.push(subTestId);
        }
      });
    this.setState({ testIdOfFirstTest: sortedArray[0] });

    return testIdsArray;
  };

  getApplicationIdsArray = (applications: any) => {
    const applicationIdsArray: string[] = [];
    const filteredApplications: string[] = applications.filter(
      (application: any) => {
        const app = application[Object.keys(application)[0]];
        if (app.hasOwnProperty("completed") && app.completed === 1) {
          return false;
        } else {
          return true;
        }
      }
    );
    filteredApplications.map((application: any) => {
      const applicationId: string = Object.keys(application)[0];
      applicationIdsArray.push(applicationId);
    });

    return applicationIdsArray;
  };

  hasCompletedTestBefore = (data: any, decryptedData: any) => {
    const redirectUrl = getRedirectUrlForExitPage(decryptedData || data);
    if (redirectUrl) {
      this.updateExitPage(redirectUrl);
    }

    if (decryptedData) {
      this.setState({
        testEventData: decryptedData
      });
      return getReusedResultsCaseWithDecryptedData(decryptedData, data);
    } else {
      return getReusedResultsCaseWithRawData(data);
    }
  };

  updateCompletedApplications = (applicationId: number) => {
    // first step, get a copy of the application object on the testEventData and add the
    // 'completed' property to it.
    let currentApplication: any;
    this.state.testEventData.application.forEach((application: any) => {
      if (Object.keys(application)[0] === String(applicationId)) {
        currentApplication = application[String(applicationId)];
      }
    });
    currentApplication.completed = 1;

    // second step, get a copy of the testEventData object, and find the index of the application
    // in that copied object's application object.
    const clonedTestEventData: any = this.state.testEventData;
    let currentApplicationIndex: string = "0";

    clonedTestEventData.application.forEach((app: any, index: string) => {
      if (Object.keys(app).includes(String(applicationId))) {
        currentApplicationIndex = index;
      }
    });

    // third step, set the new application object into the appropriate index of the copied testEventData
    // application object.
    clonedTestEventData.application[currentApplicationIndex] = {
      [applicationId]: currentApplication
    };

    // fourth step, remove the completed application Id from the applicationIdsArray so we can
    // progress to the next application, or move on from applications.
    const applicationIdsArray = this.getApplicationIdsArray(
      clonedTestEventData.application
    );

    // finally, we set all of this to the components state, which in turn updates the context, and then
    // we navigate back to the Overview page, if there are other requirements in the test event, or navigate
    // to the tests route to finish out the event.
    this.setState(
      { testEventData: clonedTestEventData, applicationIdsArray },
      () => {
        if (
          applicationIdsArray.length === 0 &&
          !this.state.testEventData.requireResume &&
          !this.state.testEventData.documents &&
          this.state.testEventData.tests === null &&
          this.state.videoInterview === null
        ) {
          navigate("/tests");
        } else {
          navigate("/overview");
        }
      }
    );
  };

  updateResumeSubmitted = (value: number) => {
    // first step, clone the testEventData from the context/state
    const clonedTestEventData: any = this.state.testEventData;

    // second step, change the value of the resumeCompleted property to true
    clonedTestEventData.resumeComplete = value;

    // finally, set the testEventData value in state equal to the cloned (modified) copy
    this.setState({ testEventData: clonedTestEventData });
  };

  updateOtherDocsSubmitted = (documentId: string, value: boolean) => {
    // first step, clone the testEventData from the context/state
    const clonedTestEventData: any = this.state.testEventData;

    // second step, find the index in the files array in which the current file resides
    let selectedIndex: number = 0;
    clonedTestEventData.documents.files.map((file: any, index: number) => {
      if (file.documentId === documentId) {
        selectedIndex = index;
      }
    });

    // third step, get a clone of the file object in that index
    const fileToUpdate = clonedTestEventData.documents.files[selectedIndex];

    // fourth step, change the complete flag to true/false value
    fileToUpdate.completed = value;

    // fourth step, update the testEventData with the modified file
    clonedTestEventData.documents.files[selectedIndex] = fileToUpdate;

    // finally, set the testEventData value in state equal to the cloned (modified) copy
    this.setState({ testEventData: clonedTestEventData });
  };

  updateSameTest = (value: boolean) => {
    this.setState({ sameTest: value });
  };

  updateExpiredMessage = () => {
    const updateMessage = this.state.testEventData
      ? this.state.testEventData.translatedText.eventHasExpired
      : null;
    this.setState({ overviewExpiredMessage: updateMessage });
  };

  // we will use this method to update the error message on the overview page when the expired time returned
  // from the node server is errantly greater than the test time (0 Time 0 Score Bug).
  updateWrongExpiredTimeMessage = () => {
    this.setState({
      expiredTimeErrorMessage: this.state?.testEventData?.translatedText
        ?.expiredTimeError
        ? this.state.testEventData.translatedText.expiredTimeError
        : "There was a problem starting your test. Please contact our Candidate Support Specialists via the chat function in the bottom right corner of the testing center window."
    });
  };

  // refactored navigateToStartOfTest
  navigateToStartOfTest = (
    data: any,
    testIdsArray: string[],
    eventId: string,
    applicationIdsArray: string[]
  ): void => {
    // if the length of the tests array and testIdsArray are equal, that means there are no completed tests.
    // but, we still have to check redis to see if the first test in the testEvent was started.
    // the API endpoint used is based on the type of test it is.
    // if the test is being resumed, the user is sent to the begin_assessment router instead of the verify route.
    const stateObject: any = {
      applicationIdsArray,
      eventId,
      jobCode: data.jobCode,
      orderId: data.orderId,
      testEventData: data,
      testEventId: data.testEventId,
      testIdsArray,
      token: data.token
    };
    if (
      data.tests &&
      testIdsArray &&
      Object.keys(data.tests).length === testIdsArray.length
    ) {
      let resumeTestData: Promise<void>;
      const testId = testIdsArray[this.state.testIndex];
      const testType: string = testId.includes("tm_")
        ? "testMakerTest"
        : getAllTests()[String(testId)];
      const resumeTestDataFetchMethod: any =
        getResumeTestDataFetchMethod(testType);
      if (resumeTestDataFetchMethod) {
        this.setState({ retrievingData: true });
        resumeTestData =
          testType !== "revelianSubTest"
            ? resumeTestDataFetchMethod(data.testEventId, testId, data.token)
            : resumeTestDataFetchMethod(eventId);
        resumeTestData.then((responseData: any) => {
          const stateObjectToUpdate = getStateObjectToUpdate(
            testId,
            data,
            responseData,
            testType,
            stateObject
          );
          this.setState(
            { ...stateObjectToUpdate, retrievingData: false },
            () => {
              if (this.state.isResumingTest && testType !== "revelianSubTest") {
                testType === "multipleChoiceTest"
                  ? navigate("/verify")
                  : navigate("/overview");
              } else {
                navigate("/verify");
              }
            }
          );
        });
      } else {
        /* This bit is necessary in case the test isn't multiple choice, testmaker, personality, mrab, clik, or game -- TT and TKT */
        this.setState(stateObject, () => {
          if (this.state.isResumingTest) {
            navigate("/overview");
          } else {
            navigate("/verify");
          }
        });
      }
    } else {
      this.setState(
        {
          ...stateObject,
          isResumingTest: true
        },
        () => {
          navigate("/overview");
        }
      );
    }
    this.setState({
      ...stateObject,
      isResumingTest: false
    });
    if (testIdsArray === null) {
      navigate("/event_id");
    }
  };

  updateLinkParams = (
    u: string,
    first: string,
    last: string,
    email: string,
    orderId: string,
    source: string,
    returnUrl: string
  ): void => {
    this.setState({
      u,
      first,
      last,
      email,
      orderId,
      source,
      optionalReturnUrl: returnUrl
    });
  };

  updateRestartCounter = (): void => {
    this.setState((state: State) => ({
      restartCounter: state.restartCounter + 1
    }));
  };

  updateReachedRestartLimit = (): void => {
    this.setState({ reachedRestartLimit: true });
  };

  updateExpiredTime = (value: number) => {
    this.setState({ expiredTime: value });
  };

  updateLastQuestionNumber = (value: number) => {
    this.setState({ lastQuestionNumber: value });
  };

  updateExitPage = (returnUrl: string) => {
    const url =
      returnUrl.slice(0, 7) === "http://" ||
      returnUrl.slice(0, 8) === "https://" ||
      returnUrl.slice(0, 13) === "http%3A%2F%2F" ||
      returnUrl.slice(0, 14) === "https%3A%2F%2F" ||
      returnUrl.slice(0, 13) === "http%3a%2f%2f" ||
      returnUrl.slice(0, 14) === "https%3a%2f%2f"
        ? returnUrl
        : `http://${returnUrl}`;
    this.setState({ optionalReturnUrl: url });
  };

  updateIsMobile = (isMobile: boolean) => {
    this.setState({ isMobile });
  };

  updateTestIndex = (testIndex: number) => {
    this.setState({ testIndex });
  };

  updateCompletedSubTestIds = (subTestId: string): string[] => {
    let completedSubTestIds: string[] = [];
    this.setState(
      state => ({
        completedSubTestIds: !state.completedSubTestIds.includes(subTestId)
          ? [...state.completedSubTestIds, subTestId]
          : state.completedSubTestIds
      }),
      () => {
        completedSubTestIds = this.state.completedSubTestIds;
      }
    );
    return completedSubTestIds;
  };

  updateToken = (token: string) => {
    this.setState({ token });
  };

  updateIsResumingTest = (value: boolean, testIdToResume: string | null) => {
    this.setState({ isResumingTest: value, testIdToResume });
  };

  updateFromEventIdLink = (value: boolean) => {
    this.setState({ fromEventIdLink: value });
  };

  updateUsedEventId = (value: boolean) => {
    this.setState({ usedEventId: value });
  };

  updateRetrievingData = (retrievingData: boolean) => {
    this.setState({ retrievingData });
  };

  setUserDisqualified = (value: boolean) => {
    this.setState({ userDisqualified: value });
  };

  updateTestEventData = (data: any) => {
    this.setState({ testEventData: data });
  };

  state: State = {
    testIndex: 0,
    eventId: "",
    jobCode: "",
    testEventData: {},
    token: "",
    testEventId: null,
    applicationIdsArray: [] as any[],
    testIdsArray: [],
    isResumingTest: false,
    usedEventId: false,
    testIdToResume: null,
    updateIsResumingTest: this.updateIsResumingTest,
    expiredTime: 0,
    eventIdCompleted: false,
    sendTestTakerData: this.sendTestTakerData,
    generateLandingPageStyle: this.generateLandingPageStyle,
    updateCompletedApplications: this.updateCompletedApplications,
    updateResumeSubmitted: this.updateResumeSubmitted,
    updateOtherDocsSubmitted: this.updateOtherDocsSubmitted,
    retrievingData: false,
    invalidEventId: false,
    errorMessage: "",
    invalidTestTakerMessage: "",
    invalidJobCode: false,
    invalidJobCodeMessage: "",
    content: "",
    restartCounter: 0,
    reachedRestartLimit: false,
    u: null,
    first: null,
    last: null,
    email: null,
    orderId: null,
    source: null,
    optionalReturnUrl: null,
    isMobile: false,
    completedSubTestIds: [],
    sameTest: false,
    updateSameTest: this.updateSameTest,
    updateRetrievingData: this.updateRetrievingData,
    updateExpiredTime: this.updateExpiredTime,
    landingPageParameters: {
      logoPlacement: "Left",
      welcomeMessage: "Assessment Center",
      welcomeMessagePlacement: "Center",
      instruction: "",
      backgroundColor: "#FFF",
      textColor: "",
      welcomeColor: "",
      formPlacement: "Center",
      formBorderColor: "#E3E3E3",
      formBackgroundColor: "#F5F5F5",
      landingPageVideo: ""
    },
    showTestCompleted: 0,
    fromEventIdLink: false,
    updateUsedEventId: this.updateUsedEventId,
    testIdOfFirstTest: null,
    eventExpirationDate: null,
    eventInvitationTimeZone: null,
    overviewExpiredMessage: null,
    updateExpiredMessage: this.updateExpiredMessage,
    revelianTestData: null,
    userDisqualified: false,
    expiredTimeErrorMessage: null,
    updateWrongExpiredTimeMessage: this.updateWrongExpiredTimeMessage,
    isAU: false,
    referrer: {
      id: null,
      url: ""
    },
    lastQuestionNumber: 0,
    updateLastQuestionNumber: this.updateLastQuestionNumber,
    configCatObject: null,
    companyAccountId: "",
    partnerAccountId: null,
    videoInterview: null,
    isVideoInterviewDiscoveryJob: false,
    videoInterviewDiscovery: null,
    isVideoInterviewDiscoveryJobCompleted: false,
    updateTestEventData: this.updateTestEventData,
    lockout: {
      lockout: 0,
      isLockout: false,
      assessment: "",
      subTestId: 0
    }
  };

  render() {
    return (
      <div
        id="app"
        className={
          window.location.pathname.includes("workplace-insights")
            ? "workplace-insights"
            : ""
        }
      >
        <React.StrictMode>
          <TestEventProvider value={this.state}>
            <React.Suspense fallback={<div />}>
              <Location>
                {({ location }) => (
                  <Router location={location}>
                    <VerifyForm
                      path="verify"
                      updateFromEventIdLink={this.updateFromEventIdLink}
                    />
                    <VerifyForm
                      path="link/index/:jobCode"
                      fetchTestEvent={this.fetchTestEventByJobCode}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateExitPage={this.updateExitPage}
                      updateIsMobile={this.updateIsMobile}
                      referrer={this.state.referrer}
                    />
                    <VerifyForm
                      path="index.php/link/index/:jobCode"
                      fetchTestEvent={this.fetchTestEventByJobCode}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/apply/:testBatteryId/:userAccountId"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="/index.php/verify/apply/:testBatteryId/:userAccountId"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="/verify/test_link/:testBatteryId/:userAccountId/:orderId"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/apply/:testBatteryId/:userAccountId/:contactInfo/"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/apply/:testBatteryId/:userAccountId/:contactInfo/:returnUrl"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateExitPage={this.updateExitPage}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/test_link/:testBatteryId/:userAccountId/:orderId/:first/:last/:email/"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/test_link/:testBatteryId/:userAccountId/:orderId/:first/:last/:email/:returnUrl"
                      fetchTestEvent={this.fetchTestEventByTestBattery}
                      retrievingData={this.state.retrievingData}
                      updateLinkParams={this.updateLinkParams}
                      updateExitPage={this.updateExitPage}
                      updateIsMobile={this.updateIsMobile}
                    />
                    <VerifyForm
                      path="verify/index/:eventId"
                      fetchTestEvent={this.fetchTestEventByEventId}
                      retrievingData={this.state.retrievingData}
                      updateIsMobile={this.updateIsMobile}
                      fromLink={true}
                      updateFromEventIdLink={this.updateFromEventIdLink}
                    />
                    <EventId
                      path="event_id"
                      retrievingData={this.state.retrievingData}
                      updateDateEventStarted={this.updateDateEventStarted}
                    />
                    <EventIdDebug path="event_id_debug" />
                    <EventIdDebugResult path="event_id_debug_results" />
                    <Overview
                      path="overview"
                      updateToken={this.updateToken}
                      updateReachedRestartLimit={this.updateReachedRestartLimit}
                      updateRestartCounter={this.updateRestartCounter}
                      updateExpiredTime={this.updateExpiredTime}
                      userDisqualified={this.state.userDisqualified}
                      updateLastQuestionNumber={this.updateLastQuestionNumber}
                    />
                    <DesktopOnly path="desktop-only" />
                    <Event
                      path="event/:eventId"
                      fetchTestEventByEventId={this.fetchTestEventByEventId}
                    />
                    <UnsupportedBrowser path="unsupported-browser" />
                    <Applications
                      path="application"
                      setUserDisqualified={this.setUserDisqualified}
                      numberOfSecondsToDelay={
                        this.state.testEventData?.exitPage
                          ?.numberOfSecondsToDelay
                          ? this.state.testEventData.exitpage
                          : 5
                      }
                    />
                    <DocumentUpload path="document_upload/:docType/" />
                    <DocumentUpload path="document_upload/:docType/:documentId" />
                    <VerifyForm path="/" />
                    <EventIdForm
                      path="/event_id_form"
                      default={true}
                      fromDisability={false}
                      fromHelp={false}
                      fetchTestEventByEventId={this.fetchTestEventByEventId}
                      retrievingData={this.state.retrievingData}
                      invalidEventId={this.state.invalidEventId}
                      errorMessage={this.state.errorMessage}
                      invalidJobCode={this.state.invalidJobCode}
                      invalidJobCodeMessage={this.state.invalidJobCodeMessage}
                    />
                    <InsightsReport path="/insights-report" />
                    <Test
                      path="tests"
                      updateTestIndex={this.updateTestIndex}
                      testIndex={this.state.testIndex}
                      updateCompletedSubTestIds={this.updateCompletedSubTestIds}
                      updateToken={this.updateToken}
                      updateRestartCounter={this.updateRestartCounter}
                      updateIsResumingTest={this.updateIsResumingTest}
                      fetchTestEventByEventId={this.fetchTestEventByEventId}
                    />
                    <NoTests path="/thanks/index" />
                    <NoTests path="/thanks/index/:returnUrl" />
                    <NoTests path="/thanks/index/:returnUrl/:language" />
                    <Content
                      path="home/faq"
                      contentType="faqs"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/faq/:locale"
                      contentType="faqs"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/copyright"
                      contentType="copyright"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/terms"
                      contentType="ODA Terms"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/privacy"
                      contentType="ODA Privacy"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="aup.htm"
                      contentType="ODA AUP"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/accommodations"
                      contentType="accommodations"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/accommodations/:locale"
                      contentType="accommodations"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/dmca"
                      contentType="Criteria DMCA"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="insight/terms.htm"
                      contentType="custom|oda_terms_47"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="insight/privacy.htm"
                      contentType="custom|oda_privacy_47"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <Content
                      path="home/workplace-faq"
                      contentType="workplace_faq"
                      fetchContent={this.fetchContent}
                      contentText={this.state.content}
                    />
                    <ErrorPage path="crossover-error" />
                    <Redirect
                      from="schedule/calendar/*"
                      to="/"
                      noThrow={true}
                      state={{ calendarRedirect: true }}
                    />
                    <WorkplaceInsights path="workplace-insights/:param1/:param2" />
                    <WorkplaceInsightsPersonify path="workplace-insights-personify/:param1/:param2" />
                    <RevelianTest
                      path="emotify/:eventId"
                      updateTestIndex={this.updateTestIndex}
                      updateCompletedSubTestIds={this.updateCompletedSubTestIds}
                      fetchTestEventByEventId={this.fetchTestEventByEventId}
                      generateLandingPageStyle={this.generateLandingPageStyle}
                      updateExitPage={this.updateExitPage}
                      updateConfigCatObject={this.updateConfigCatObject}
                      updateTestEventData={this.updateTestEventData}
                    />
                    <CandidateRequestForm path="candidate-request" />
                    <ReuseResults
                      path="event-complete"
                      showTestCompleted={this.state.showTestCompleted}
                    />
                    <CandidateVerificationForm path="candidate-verification/:track/:requestId" />
                    <CustomerResponseForm path="customer-response/:token/:responseId/:testTakerIds/:answer" />
                    <AttorneyVerificationForm path="attorney-verification/:token/:answer" />
                    <LinkResults path="link-results" />
                    <LinkResultsVerify path="link-results-verify" />
                    <LinkResultsCheck path="linkResults" />
                    <LinkResultsSuccess path="link-results-success" />
                    <VideoInterviewTestEventCompleted
                      path="video-interview-complete/:eventId"
                      generateLandingPageStyle={this.generateLandingPageStyle}
                    />
                  </Router>
                )}
              </Location>
            </React.Suspense>
          </TestEventProvider>
        </React.StrictMode>
      </div>
    );
  }
}

export default App;
