import React, { Component } from "react";
import { sendTimeRemaining } from "../utils/redisHelpers";
import { formatTime } from "../utils/shared";
import PageVisibility from "react-page-visibility";

interface State {
  seconds: number;
  questionNumber: number | null | undefined;
  notVisibleStartTime: number;
  totalTime: number;
}

interface Props {
  seconds: number;
  startTimer: boolean;
  color: string;
  pauseTimer: boolean | null;
  questionNumber: number | null;
  testData: any;
  testMakerTestId: any;
  token: string;
  testEventId: number;
  onCompletion: () => void;
  timeFactor: number;
}
export default class ContinuousTimer extends Component<Props, State> {
  state = {
    seconds: this.props.seconds,
    questionNumber: this.props.questionNumber,
    notVisibleStartTime: 0,
    startTimeInMS: Date.now(),
    totalTime: this.props.seconds
  };
  // declare class property type
  timerID: any;
  // set the interval to countdown the clock, if the startTimer prop was initially set to true
  componentDidMount() {
    if (this.props.startTimer) {
      this.startTimer();
    }
  }

  componentDidUpdate(prevProps: Props) {
    // check if the startTimer has been set to true on the parent component, and if so, start the timer
    // used primarily with the TypingTest and TenKeyTest components
    if (this.props.startTimer !== prevProps.startTimer) {
      this.startTimer();
    }
    // check if the pauseTimer prop has been set to true, and if so, clear the timer interval so that
    // the timer stops
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      this.props.pauseTimer
    ) {
      clearInterval(this.timerID);
    }
    // check if the pauseTimer prop has been set to false, and if so, restart the timer
    if (
      this.props.pauseTimer !== prevProps.pauseTimer &&
      !this.props.pauseTimer
    ) {
      this.startTimer();
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.questionNumber && props.questionNumber !== state.questionNumber) {
      if (props.testData.timeQuestions) {
        return {
          seconds: props.seconds,
          questionNumber: props.questionNumber,
          startTimeInMS: Date.now(),
          totalTime: props.seconds
        };
      }
      return {
        seconds: props.seconds,
        questionNumber: props.questionNumber
      };
    }
    return null;
  }

  // clean up the setInterval
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // method to start the timer
  startTimer = () => {
    this.timerID = setInterval(() => this.tick(), 1000);
  };

  // method to decrement the seconds value in state whenever it is called
  // if time is up, calls completion function and clears the interval
  tick = () => {
    const elapsedTime = this.state.seconds - 1;

    const newTimeRemaining =
      this.props.testData.timeQuestions && this.state.questionNumber !== null
        ? this.props.testData.questions[this.state.questionNumber].timeLimit *
            this.props.timeFactor -
          this.state.seconds
        : this.props.testData.timeAllowed * this.props.timeFactor - elapsedTime;

    sendTimeRemaining(
      newTimeRemaining,
      this.props.testEventId,
      `tm_${this.props.testMakerTestId}`,
      this.props.token
    );
    this.setState({
      seconds: elapsedTime
    });

    if (elapsedTime <= 0) {
      clearInterval(this.timerID);
      this.props.onCompletion();
    }
  };

  handlePageVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      const currentTime: number = Date.now();

      // we will use Math.round here to hopefully "average out" there minor differences in the time.
      // there is the potential to use Math.ceil instead, if we want to guarantee the candidate does not
      // go over the time limit, but it may result in slightly less time made available for the candidate.
      const elapsedTime = Math.round(
        (currentTime - this.state.startTimeInMS) / 1000
      );
      this.setState({ seconds: this.state.totalTime - elapsedTime });
    }
  };

  render() {
    return (
      <PageVisibility onChange={this.handlePageVisibilityChange}>
        <div id="timer" style={{ color: this.props.color }}>
          {formatTime(this.state.seconds)}
        </div>
      </PageVisibility>
    );
  }
}
