import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import Timer from "../../Components/Timer";
import { TestEventContext } from "../../Contexts/TestEventContext";
import { TypingTestAnswerData } from "../../Interfaces/AnswerData";
import { nodeApiURL } from "../../utils/constants";

interface Props {
  testData: any;
  subTestId: string;
  testMakerTestId: string;
  timeAllowed: number;
  title: string;
  endSubTest: () => void;
}

interface State {
  typedPassage: string;
  startTimer: boolean;
  tmTextToTypeScrollHeight: string;
}

export default class TypingTest extends Component<Props, State> {
  state = {
    typedPassage: "",
    startTimer: false,
    tmTextToTypeScrollHeight: "100"
  };

  // define the context type so that we can use this.context throughout the class
  static readonly contextType = TestEventContext;

  componentDidMount() {
    // we need to disable the pasting functionality to guard against improper test results
    const tmTypingTextArea: any = document.getElementById(
      "tm-typing-test-textarea"
    );
    if (tmTypingTextArea !== null) {
      tmTypingTextArea.onpaste = this.handlePaste;
      tmTypingTextArea.ondrop = this.handlePaste;
    }
    const typingTextArea: any = document.getElementById("typing-test-textarea");
    if (typingTextArea !== null) {
      typingTextArea.onpaste = this.handlePaste;
      typingTextArea.ondrop = this.handlePaste;
    }
    const textToTypeElement: HTMLElement | null =
      document.getElementById("tm-text-to-type");
    if (textToTypeElement) {
      this.setState({
        tmTextToTypeScrollHeight: String(textToTypeElement.scrollHeight)
      });
    }
  }

  handlePaste = () => {
    return false;
  };

  handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!this.state.startTimer) {
      this.setState({ startTimer: true });
    }
    this.setState({ typedPassage: event.target.value });
  };

  endTypingTest = () => {
    const answerData: TypingTestAnswerData = {
      testEventId: this.context.testEventId,
      subTestId: this.props.subTestId,
      testMakerTestId: this.props.testMakerTestId,
      typedPassage: this.state.typedPassage
    };
    this.sendTypedPassageToRedis(answerData, this.context.token);
  };

  sendTypedPassageToRedis = async (
    answerData: TypingTestAnswerData,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${nodeApiURL}/sendTypingTestAnswer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(answerData)
      });
      const responseData = await response.json();
      if (responseData.submitted) {
        this.props.endSubTest();
      }
    } catch (error) {
      console.log("error: ", error);
    }
    return true;
  };

  render() {
    const { testData } = this.props;

    return (
      <ScrollSync>
        <Container>
          <Row>
            <Col xl={10} lg={10} md={10} sm={9}>
              <h5 className="typing-test-header">{this.props.title}</h5>
            </Col>
            <Col xl={2} lg={2} md={2} sm={3}>
              <Timer
                seconds={this.props.timeAllowed}
                onCompletion={this.endTypingTest}
                startTimer={this.state.startTimer}
                color="red"
                pauseTimer={null}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!this.props.testMakerTestId ? (
                <React.Fragment>
                  <p>
                    When you are ready to start, type as much of the passage
                    below as you can into the box below. You have one minute to
                    type, and the timer will not start until you begin typing.
                  </p>
                  <hr />
                  <br />
                  <h6>
                    <strong>Text to Type</strong>
                  </h6>
                  <pre
                    className="typingPassage language-html no-select"
                    dangerouslySetInnerHTML={{
                      __html: testData.questions[0].replace(/\r?\n/g, "<br />")
                    }}
                  />
                  <br />
                  <h6>
                    <strong>Type Passage Below</strong>
                  </h6>
                  <div id="typingAreaContainer">
                    <textarea
                      id="typing-test-textarea"
                      value={this.state.typedPassage}
                      onChange={this.handleInput}
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck={false}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <Row>
                  <Col>
                    <h6>
                      <strong>Text to Type</strong>
                    </h6>
                    <ScrollSyncPane>
                      <pre
                        id="tm-text-to-type"
                        className="tm-typingPassage language-html no-select"
                        dangerouslySetInnerHTML={{
                          __html: testData.questions[0].replace(
                            /\r?\n/g,
                            "<br />"
                          )
                        }}
                      />
                    </ScrollSyncPane>
                    <br />
                  </Col>
                  <Col>
                    <h6>
                      <strong>Type Passage Below</strong>
                    </h6>
                    <ScrollSyncPane>
                      <div id="tm-typing-test-textarea-container">
                        <textarea
                          id="tm-typing-test-textarea"
                          style={{
                            minHeight: `${this.state.tmTextToTypeScrollHeight}px`
                          }}
                          value={this.state.typedPassage}
                          onChange={this.handleInput}
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck={false}
                        />
                      </div>
                    </ScrollSyncPane>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </ScrollSync>
    );
  }
}
