import React, { FunctionComponent, useContext, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import useGetData from "../CustomHooks/useGetData";
import { TokenContext } from "../Providers/TokenProvider";
import { getAllTests } from "../utils/Reference/SubTestDataMaps";
import { TestType } from "../utils/Reference/TestType";
import LockoutController from "./components/lockout/LockoutController";
import DirectAssessment from "./DirectAssessment";
import NetworkError from "./utility/error/network/NetworkError";
import Loader from "./utility/loader/Loader";

const Direct: FunctionComponent = () => {
  const { subTestId, eventId, returnUrl, assessment } =
    useContext(TokenContext);
  const testEventContext = useContext(TestEventContext);
  const testLibrary = getAllTests();
  const testType = String(subTestId).includes("tm_")
    ? TestType.TEST_MAKER
    : testLibrary[subTestId];
  const [isComplete, setIsComplete] = useState(false);
  const { data, isLoading, error } = useGetData({
    eventId,
    subTestId,
    testType,
    assessment
  });

  const endTestHandler = () => {
    setIsComplete(() => true);
    window.location.replace(returnUrl);
  };

  if (error) {
    return <NetworkError />;
  }

  if (isLoading || isComplete) {
    return <Loader />;
  }

  if (data) {
    testEventContext.testEventData = data;
    testEventContext.eventId = data.eventId;
    testEventContext.testEventId = data.testEventId;
    testEventContext.token = data.token;
    testEventContext.testIndex = 0;
    testEventContext.testIdsArray = [`${subTestId}`];
    testEventContext.lockout = data.lockout;
    return (
      <div style={{ padding: "1rem" }}>
        <LockoutController>
          <DirectAssessment
            testType={testType}
            data={{
              ...data,
              subTestId,
              dataLoaded: true,
              returnUrl,
              testData: data.tests[subTestId].details,
              timeFactor: data.timeFactor ?? 1,
              timeAllowed: data.tests[subTestId].details.timeAllowed,
              endSubTest: endTestHandler
            }}
          />
        </LockoutController>
      </div>
    );
  }

  return <NetworkError />;
};

export default Direct;
