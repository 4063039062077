import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import PersonalityTest from "../../Tests/TestDisplays/PersonalityTest";
import { getPersonalityTestStartingQuestion } from "../../utils/redisHelpers";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const Personality: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getPersonalityTestStartingQuestion>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }
  return <PersonalityTest {...props} {...data} />;
};

export default Personality;
