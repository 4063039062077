import React, { FunctionComponent } from "react";
import useResume from "../../CustomHooks/useResume";
import { EventDataWithTestProps } from "../../Interfaces/EventData";
import MultipleChoiceTest from "../../Tests/TestDisplays/MultipleChoice/MultipleChoiceTest";
import { getMultipleChoiceStartingQuestion } from "../../utils/redisHelpers";
import ExpiredTimeError from "../utility/error/expired-time/ExpiredTimeError";
import NetworkError from "../utility/error/network/NetworkError";
import Loader from "../utility/loader/Loader";

const MultipleChoice: FunctionComponent<
  EventDataWithTestProps & {
    resumeMethod: ReturnType<typeof getMultipleChoiceStartingQuestion>;
  }
> = ({ resumeMethod: method, ...props }) => {
  const { data, error } = useResume({ method });

  if (error) {
    return <NetworkError />;
  }

  if (!data) {
    return <Loader />;
  }

  if (data.expiredTime > props.timeAllowed) {
    return <ExpiredTimeError message={props.translatedText.expiredTimeError} />;
  }

  return (
    <MultipleChoiceTest
      timeRemaining={props.timeAllowed - data.expiredTime}
      startingQuestion={data.questionNumber}
      {...props}
    />
  );
};

export default MultipleChoice;
