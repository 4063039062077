// eslint-disable-entire-file
import React from "react";

const LockoutIcon = () => (
  <svg
    width="340"
    height="200"
    viewBox="0 0 340 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_48_61049"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="340"
      height="200"
    >
      <rect width="340" height="199.005" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_48_61049)">
      <ellipse
        cx="170"
        cy="165.174"
        rx="150"
        ry="149.254"
        fill="url(#paint0_linear_48_61049)"
      />
      <mask
        id="mask1_48_61049"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="15"
        width="300"
        height="300"
      >
        <ellipse
          cx="170"
          cy="165.174"
          rx="150"
          ry="149.254"
          fill="url(#paint1_linear_48_61049)"
        />
      </mask>
      <g mask="url(#mask1_48_61049)">
        <path
          d="M154.482 166.762L99.0031 220.096"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M161.672 159.86L156.277 165.038"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M182.491 139.841L163.476 158.13"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M207.441 115.872L184.296 138.114"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M213.679 109.872L209.246 114.142"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M236.404 88.0302L215.483 108.151"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M250.253 74.7225L238.208 86.3015"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M258.396 66.8984L252.057 72.9916"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M281.761 44.45L260.2 65.1687"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M287.367 39.0659L283.565 42.7202"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M312.94 14.4776L289.171 37.3363"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.342 194.103L108.951 201.209"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M153.182 158.696L118.293 192.235"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M167.635 144.809L155.124 156.837"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M183.105 129.942L169.577 142.947"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M193.565 119.891L185.047 128.08"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.319 98.9832L195.507 118.029"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M236.074 79.0373L217.27 97.1235"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M243.777 71.646L238.025 77.1757"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M269.964 46.477L245.719 69.7756"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M292.45 24.8589L271.906 44.6093"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M300.144 17.4672L294.392 22.997"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M325.699 -7.09419L302.095 15.6061"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M269.196 28.5933L283.458 14.8835"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M250.593 46.4713L267.392 30.3223"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M241.178 55.5183L248.789 48.2011"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M219.781 76.0828L239.373 57.2482"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.313 97.6748L217.977 77.81"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M188.218 106.414L195.509 99.4046"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M172.152 121.854L186.413 108.144"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M114.869 176.91L170.347 123.577"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M107.679 183.812L113.065 178.634"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.8506 203.822L105.875 185.542"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M281.881 25.7129L304.459 4.00777"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.451 42.4629L279.93 27.5819"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M249.998 56.3523L262.509 44.3333"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M238.74 67.1699L248.046 58.2237"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M218.736 86.3956L236.79 69.0403"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M210.603 94.21L216.785 88.2664"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M183.546 120.224L208.661 96.0711"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M162.342 140.592L181.595 122.083"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.999 149.568L160.4 142.453"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M116.169 184.967L151.057 151.427"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M101.715 198.853L114.218 186.834"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.9787 188.625L73.7257 207.134"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.321 179.649L94.9204 186.755"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.151 144.25L104.263 177.79"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M153.605 130.355L141.093 142.383"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M169.075 115.497L155.556 128.493"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M179.535 105.445L171.017 113.625"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M206.738 79.2995L181.477 103.576"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M222.044 64.5836L208.68 77.4305"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M229.747 57.1911L223.995 62.7208"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M255.934 32.0223L231.689 55.3211"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.42 10.4132L257.876 30.1546"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M236.563 32.0165L253.362 15.8675"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M227.147 41.0639L234.759 33.7467"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M200.301 66.8663L225.343 42.7926"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M183.283 83.2193L198.496 68.5936"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.188 91.9588L181.479 84.9497"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.122 107.399L172.383 93.6892"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M100.839 162.456L156.318 109.122"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.6487 169.357L99.0345 164.18"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M72.8292 189.376L91.8443 171.087"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M47.8793 213.346L71.025 191.104"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.2992 183.804L69.0461 202.313"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M97.6416 174.827L90.2498 181.942"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M123.581 149.896L99.5835 172.966"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M148.934 125.54L125.523 148.038"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.396 110.675L150.876 123.672"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.856 100.624L166.346 108.813"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M191.169 84.9457L176.806 98.7613"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.373 59.7694L193.11 83.0857"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.068 52.3696L219.316 57.8994"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M251.254 27.201L227.018 50.5088"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M273.741 5.59179L253.205 25.3423"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M-2.03533 56.4707L53.4432 3.13695"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.4378 20.1528L64.6909 1.64398"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M36.0949 29.1291L43.4867 22.0143"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M-0.743769 64.5276L34.1445 30.988"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M37.5681 46.3222L-17.9013 99.6472"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M44.7673 39.4117L39.3724 44.598"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M65.5872 19.402L46.5631 37.6906"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.528 -4.56765L67.3913 17.6746"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M36.2774 38.2563L1.38919 71.7958"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.7309 24.37L38.2191 36.3981"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M66.2012 9.50251L52.6727 22.508"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M59.4679 34.607L78.721 16.0982"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.125 43.5747L57.5166 36.4688"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M13.286 78.9819L48.1742 45.4425"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M32.0205 5.1012L8.61821 27.5988"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.0573 20.1449L20.6716 25.3224"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M46.8858 0.134929L27.8618 18.4146"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M309.547 213.302L330.21 193.437"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M318.972 194.918L293.71 219.203"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M312.534 182.493L337.576 158.411"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M295.516 198.846L310.73 184.212"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M329.598 175.388L305.344 198.714"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M316.056 113.922L332.864 97.7646"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M306.649 122.961L314.251 115.652"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M290.693 138.296L304.845 124.692"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M262.776 165.118L288.889 140.023"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M253.689 173.856L260.98 166.847"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M237.615 189.305L251.885 175.586"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M180.341 244.353L235.81 191.028"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M315.46 123.804L327.972 111.776"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M304.203 134.622L313.519 125.667"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M284.199 153.847L302.262 136.483"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M276.075 161.653L282.257 155.71"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M249.009 187.667L274.124 163.523"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M227.814 208.044L247.067 189.535"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M272.905 183.315L249.768 205.566"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M279.151 177.324L274.709 181.594"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M301.875 155.482L280.955 175.594"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M315.725 142.175L303.68 153.754"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M323.867 134.35L317.529 140.444"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M248.569 197.393L235.049 210.39"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M259.029 187.342L250.519 195.531"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M280.791 166.435L260.979 185.481"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M301.547 146.489L282.733 164.566"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M309.241 139.088L303.488 144.618"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M335.427 113.919L311.191 137.227"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.243 187.605L146.354 221.145"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M195.696 173.719L183.184 185.747"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.166 158.852L197.638 171.857"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.627 148.8L213.108 156.989"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M243.38 127.893L223.568 146.939"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.135 107.947L245.322 126.024"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M271.829 100.546L266.077 106.076"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M298.016 75.3771L273.78 98.6849"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M320.503 53.768L299.967 73.5185"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.882 177.972L136.994 211.511"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M186.336 164.085L173.833 176.104"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M201.806 149.218L188.287 162.215"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.266 139.167L203.757 147.347"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M234.029 118.259L214.217 137.296"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M254.784 98.3134L235.971 116.391"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M262.478 90.9124L256.726 96.4422"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M288.665 65.7438L264.42 89.0515"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M311.151 44.1346L290.607 63.8849"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M318.854 36.7428L313.102 42.2726"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M318.233 149.077L327.549 140.122"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M298.229 168.302L316.292 150.938"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M290.104 176.108L296.287 170.164"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M263.039 202.122L288.154 177.977"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M300.582 44.9887L323.16 23.2836"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M283.152 61.7381L298.641 46.8484"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M268.698 75.628L281.21 63.5999"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M257.441 86.4457L266.757 77.4907"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M237.437 105.671L255.5 88.3071"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M229.313 113.477L235.495 107.533"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M202.247 139.491L227.362 115.347"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.043 159.867L200.305 141.35"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.709 168.835L179.101 161.729"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.87 204.242L169.758 170.703"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M214.396 192.983L190.994 215.481"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M229.867 178.127L216.347 191.123"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M240.327 168.075L231.809 176.255"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M256.64 152.397L242.269 166.204"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M282.836 127.212L258.582 150.538"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M290.539 119.821L284.787 125.351"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M316.725 94.6524L292.48 117.951"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M339.212 73.0433L318.667 92.7847"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M306.533 85.8265L322.022 70.9367"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M292.079 99.7162L304.591 87.6882"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M280.823 110.534L290.138 101.579"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.818 129.759L278.881 112.395"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M252.694 137.565L258.876 131.622"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.628 163.579L250.743 139.435"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M204.433 183.956L223.686 165.438"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M195.09 192.923L202.482 185.817"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.251 228.331L193.139 194.791"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M209.726 188.173L197.214 200.201"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.187 173.306L211.668 186.303"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M235.648 163.255L227.138 171.435"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M262.851 137.109L237.598 161.394"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.165 122.402L264.802 135.249"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M285.859 115.001L280.107 120.53"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M312.046 89.8318L287.81 113.14"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M334.533 68.2225L313.988 87.9729"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M309.933 54.6223L332.511 32.9172"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M292.511 71.3721L307.991 56.4911"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.049 85.2617L290.561 73.2336"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M266.792 96.0789L276.107 87.1239"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M246.788 115.305L264.85 97.9408"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M238.664 123.111L244.846 117.176"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.597 149.124L236.722 124.98"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M190.402 169.501L209.655 150.992"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.06 178.477L188.452 171.363"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M144.221 213.876L179.109 180.337"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M286.935 197.769L263.798 220.02"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M293.172 191.778L288.739 196.04"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M315.905 169.937L294.976 190.048"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M329.755 156.629L317.701 168.208"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M229.253 188.017L210.238 206.306"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M254.203 164.048L231.057 186.299"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.441 158.057L256.008 162.318"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M283.165 136.207L262.245 156.327"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M297.015 122.899L284.97 134.487"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M305.157 115.074L298.819 121.177"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M328.522 92.6263L306.961 113.345"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M162.532 168.338L127.644 201.877"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M176.986 154.452L164.483 166.471"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M192.456 139.584L178.937 152.581"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M202.916 129.533L194.407 137.713"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M230.119 103.387L204.867 127.663"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M245.425 88.6715L232.061 101.518"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M253.128 81.279L247.376 86.8088"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M279.315 56.1102L255.07 79.4088"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M301.801 34.5008L281.256 54.2512"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M309.495 27.1005L303.743 32.6301"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M335.05 2.53899L311.446 25.2393"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M182.533 195.671L127.055 248.995"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M189.724 188.76L184.338 193.947"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M210.552 168.75L191.528 187.039"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M235.493 144.781L212.356 167.023"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M241.74 138.781L237.297 143.051"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.464 116.939L243.544 137.051"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M278.313 103.632L266.268 115.211"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M286.456 95.8075L280.117 101.901"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M309.812 73.359L288.26 94.0779"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M315.427 67.9661L311.617 71.6292"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M341 43.3865L317.222 66.2362"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M305.712 170.413L275.009 199.928"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M315.576 160.936L307.653 168.553"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M323.27 153.543L317.518 159.072"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M57.7443 194.547L32.6292 218.691"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.9484 174.171L59.6953 192.68"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.2911 165.194L80.8903 172.309"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M125.121 129.795L90.2332 163.335"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.574 115.909L127.072 127.928"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M155.045 101.042L141.526 114.038"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M165.505 90.9904L156.996 99.1706"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M187.258 70.083L167.456 89.1201"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M208.023 50.1281L189.209 68.2143"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.717 42.7365L209.965 48.2662"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M241.904 17.5675L217.659 40.8752"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M264.39 -4.04149L243.845 15.7088"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.3937 184.905L23.2783 209.058"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M69.5976 164.537L50.3445 183.046"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.9402 155.561L71.5395 162.667"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.77 120.163L80.8818 153.702"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.224 106.267L117.712 118.295"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M145.694 91.4088L132.175 104.405"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M156.154 81.3574L147.636 89.5373"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M177.908 60.4409L158.096 79.4868"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M198.663 40.495L179.858 58.5813"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M206.366 33.1034L200.614 38.6332"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M232.553 7.93459L208.308 31.2333"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.586 17.8101L225.098 5.79095"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M201.329 28.6276L210.644 19.6816"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.325 47.8624L199.378 30.4981"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M173.191 55.6681L179.374 49.7245"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.135 81.6819L171.25 57.5288"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.931 102.05L144.184 83.5407"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.588 111.026L122.988 103.92"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.7576 146.425L113.646 112.885"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M64.3039 160.32L76.8066 148.292"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.834 175.178L62.3533 162.181"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M38.3741 185.23L46.8832 177.049"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M5.72046 216.614L36.4228 187.098"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M221.937 27.4526L234.449 15.4245"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M210.68 38.2705L219.995 29.3154"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M190.676 57.4958L208.738 40.1318"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M182.551 65.3018L188.734 59.3582"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M155.485 91.3157L180.601 67.1626"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.282 111.683L153.535 93.1744"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.948 120.66L132.339 113.554"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.1087 156.067L122.997 122.528"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.6548 169.954L86.1666 157.926"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.1848 184.821L71.7132 171.816"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M47.7244 194.872L56.2427 186.683"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M31.9614 210.022L45.7829 196.735"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M39.0433 175.271L13.9282 199.415"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.2474 154.904L40.9853 173.413"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M69.5808 145.927L62.1891 153.033"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.42 110.52L71.5316 144.059"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M120.873 96.6333L108.362 108.661"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.344 81.7664L122.815 94.7718"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.804 71.7146L138.286 79.9036"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M174.007 45.5692L148.746 69.8542"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M189.313 30.8622L175.949 43.7091"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.016 23.4697L191.264 28.9994"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M223.193 -1.6993L198.957 21.5996"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M173.019 27.9062L192.611 9.06272"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M150.551 49.4986L171.215 29.6338"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.456 58.237L148.747 51.2279"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M125.391 73.6775L139.652 59.9676"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68.1074 128.734L123.586 75.4004"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.9174 135.636L66.3031 130.458"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M40.0889 155.645L59.1129 137.366"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M15.1478 179.624L38.2846 157.373"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M191.979 18.9944L201.284 10.0481"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M171.974 38.2201L190.028 20.8649"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M163.841 46.0344L170.023 40.0908"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.775 72.0395L161.899 47.8954"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M115.58 92.4163L134.833 73.9075"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.237 101.393L113.629 94.2779"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M69.4068 136.791L104.295 103.252"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M54.9537 150.678L67.4563 138.659"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M39.4832 165.545L53.0025 152.548"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M29.0233 175.597L37.5323 167.417"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M7.2606 196.504L27.0723 177.467"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M25.0131 160.817L-0.102095 184.97"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M46.217 140.449L26.9639 158.958"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M55.5597 131.473L48.159 138.579"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.3901 96.0656L57.5018 129.605"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M106.843 82.1791L94.3316 94.2071"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M122.314 67.3116L108.785 80.3171"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.774 57.2603L124.256 65.4493"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.977 31.1235L134.715 55.3996"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M175.283 16.4079L161.919 29.2549"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M153.539 18.6903L178.581 -5.38349"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M136.521 35.0433L151.735 20.4177"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127.426 43.7827L134.717 36.7736"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M111.36 59.2232L125.621 45.5134"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M54.0771 114.28L109.556 60.946"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M46.8868 121.181L52.2725 116.004"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.0583 141.2L45.0824 122.911"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1.11738 165.169L24.2632 142.918"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M20.3421 156.005L-4.78205 180.149"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M41.5369 135.628L22.2838 154.137"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.8797 126.652L43.4789 133.766"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M76.819 101.721L52.8213 124.791"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.163 77.3649L78.7607 99.8626"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M117.633 62.4995L104.114 75.4961"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M128.093 52.448L119.584 60.6282"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M144.406 36.7701L130.044 50.5768"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M170.611 11.5939L146.348 34.9102"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M78.6199 25.502L92.8903 11.7833"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M21.3461 80.559L76.8246 27.2252"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.0133 23.8638L115.137 -0.280256"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68.8183 44.2407L88.0714 25.7319"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M59.4757 53.2167L66.8674 46.1019"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M22.6456 88.6154L57.5339 55.076"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M8.1832 102.502L20.6949 90.4737"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.9494 70.4103L5.47979 123.744"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68.1488 63.4998L62.7539 68.6861"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.9681 43.4896L69.9439 61.7783"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M113.909 19.5204L90.7721 41.7627"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M120.156 13.5202L115.713 17.7908"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M22.8194 97.7516L15.4277 104.857"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M59.6584 62.3442L24.7702 95.8837"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.1119 48.4578L61.6002 60.4858"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.5823 33.5902L76.0538 46.5957"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M100.042 23.5389L91.5239 31.7279"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M121.796 2.63156L101.984 21.6775"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M104.044 38.3184L129.168 14.1744"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.849 58.6953L102.102 40.1866"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.506 67.6718L80.8978 60.557"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M36.6671 103.07L71.5553 69.5307"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M22.2136 116.957L34.7253 104.928"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M6.74312 131.824L20.2716 118.818"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.9793 84.8649L19.5008 138.19"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.1696 77.9539L76.7837 83.1403"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M102.998 57.9442L83.9743 76.2239"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127.939 33.9658L104.802 56.217"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M134.186 27.9747L129.744 32.2453"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M156.91 6.13342L135.99 26.2448"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M27.5069 121.174L8.25382 139.682"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M36.8496 112.197L29.4578 119.312"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.6793 76.7985L38.7911 110.338"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M88.142 62.9122L75.6303 74.9402"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M103.603 48.0446L90.084 61.0412"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M114.063 37.9932L105.554 46.1823"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.717 6.60952L116.014 36.1339"
          stroke="#425CC7"
          strokeWidth="0.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </g>
    <path
      d="M217.5 194.457C217.5 191.44 219.945 188.995 222.962 188.995H237.295C240.311 188.995 242.757 191.44 242.757 194.457V197.26H217.5V194.457Z"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <line
      x1="338.5"
      y1="197.877"
      x2="1.5"
      y2="197.877"
      stroke="#001F4E"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <rect
      x="79.2314"
      y="71.715"
      width="181.612"
      height="102.135"
      rx="4.18533"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <rect
      x="117.239"
      y="61.2015"
      width="105.595"
      height="102.135"
      rx="4.18533"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <rect
      x="134.616"
      y="68.9476"
      width="86.9092"
      height="86.9136"
      fill="#D5F5F6"
    />
    <path
      d="M117.239 65.3868C117.239 63.0753 119.113 61.2015 121.425 61.2015H218.649C220.961 61.2015 222.834 63.0753 222.834 65.3868V72.6575H117.239V65.3868Z"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <ellipse
      cx="122.526"
      cy="67.5866"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <ellipse
      cx="127.957"
      cy="67.5866"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <ellipse
      cx="133.386"
      cy="67.5866"
      rx="1.35744"
      ry="1.31418"
      fill="#001F4E"
    />
    <path
      d="M79.2314 155.823H260.843V169.664C260.843 171.976 258.969 173.85 256.658 173.85H83.4168C81.1053 173.85 79.2314 171.976 79.2314 169.664V155.823Z"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <ellipse
      cx="170.037"
      cy="164.836"
      rx="2.71488"
      ry="2.62837"
      fill="#001F4E"
    />
    <path
      d="M150.069 197.505L153.676 174.221H186.399L190.006 197.505H150.069Z"
      fill="white"
      stroke="#001F4E"
      strokeWidth="3"
    />
    <path
      d="M168.062 111.911L168.445 118.036C168.445 118.419 168.719 118.637 169.102 118.637H171.344C171.672 118.637 172 118.364 172 118.036L172.383 111.911C172.383 111.528 172.109 111.2 171.727 111.2H168.719C168.336 111.2 168.062 111.528 168.062 111.911ZM172.547 121.7C172.547 120.442 171.508 119.403 170.25 119.403C168.938 119.403 167.953 120.442 167.953 121.7C167.953 123.012 168.938 123.997 170.25 123.997C171.508 123.997 172.547 123.012 172.547 121.7ZM172.492 102.012C171.508 100.317 168.938 100.262 167.953 102.012L154.828 124.817C153.844 126.512 155.102 128.7 157.125 128.7H183.32C185.344 128.7 186.602 126.567 185.617 124.817L172.492 102.012ZM157.398 125.637L169.922 103.872C170.086 103.653 170.359 103.653 170.523 103.872L183.047 125.637C183.211 125.856 183.047 126.075 182.773 126.075H157.672C157.398 126.075 157.234 125.856 157.398 125.637Z"
      fill="#D50057"
    />
    <circle cx="242" cy="134" r="4" fill="#D50057" />
    <path
      opacity="0.4"
      d="M251 134C251 138.971 246.971 143 242 143C237.029 143 233 138.971 233 134C233 129.029 237.029 125 242 125C246.971 125 251 129.029 251 134ZM234.8 134C234.8 137.976 238.024 141.2 242 141.2C245.976 141.2 249.2 137.976 249.2 134C249.2 130.024 245.976 126.8 242 126.8C238.024 126.8 234.8 130.024 234.8 134Z"
      fill="#D50057"
    />
    <path
      opacity="0.2"
      d="M256 134C256 141.732 249.732 148 242 148C234.268 148 228 141.732 228 134C228 126.268 234.268 120 242 120C249.732 120 256 126.268 256 134ZM230.8 134C230.8 140.186 235.814 145.2 242 145.2C248.186 145.2 253.2 140.186 253.2 134C253.2 127.814 248.186 122.8 242 122.8C235.814 122.8 230.8 127.814 230.8 134Z"
      fill="#D50057"
    />
    <g filter="url(#filter0_d_48_61049)">
      <path
        d="M270 146L245 136L250 161L254 151L263.5 162L267.5 158L258 148L270 146Z"
        fill="#81E0E5"
      />
      <path
        d="M270.371 145.072C270.788 145.238 271.042 145.662 270.994 146.107C270.946 146.553 270.607 146.913 270.164 146.986L260.022 148.677L268.225 157.311C268.599 157.704 268.591 158.324 268.207 158.707L264.207 162.707C264.011 162.904 263.741 163.009 263.463 162.999C263.186 162.989 262.925 162.864 262.743 162.654L254.318 152.898L250.928 161.371C250.764 161.782 250.349 162.036 249.909 161.996C249.468 161.955 249.106 161.63 249.019 161.196L244.019 136.196C243.947 135.836 244.078 135.466 244.361 135.231C244.643 134.997 245.031 134.935 245.371 135.072L270.371 145.072Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_48_61049"
        x="227"
        y="118"
        width="69.0001"
        height="70"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.0705882 0 0 0 0 0.172549 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_48_61049"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_48_61049"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_48_61049"
        x1="170"
        y1="42.1564"
        x2="170"
        y2="314.428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#425CC7" stopOpacity="0" />
        <stop offset="1" stopColor="#425CC7" stopOpacity="0.13" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_48_61049"
        x1="165.313"
        y1="-31.8874"
        x2="165.313"
        y2="347.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#425CC7" stopOpacity="0" />
        <stop offset="1" stopColor="#425CC7" />
      </linearGradient>
    </defs>
  </svg>
);

export default LockoutIcon;
