import Language from "../../Interfaces/Language";

export const zh_cn: Language = {
  common: {
    help: "需要帮助？",
    helpCenter: "帮助中心",
    expect: "在这些测试中会出现什么情况？",
    faq: "常见问题",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853529-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98",
    copyright: "版权所有",
    copyrightNotices: "版权声明",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994366-%E7%89%88%E6%9D%83%E5%A3%B0%E6%98%8E",
    terms: "使用条款",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994198-%E4%BD%BF%E7%94%A8%E6%9D%A1%E6%AC%BE%E5%92%8C%E6%9D%A1%E4%BB%B6",
    privacy: "隐私声明",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990017-%E9%9A%90%E7%A7%81%E7%AD%96%E7%95%A5",
    dmca: "DMCA政策",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7856746-%E6%95%B0%E5%AD%97%E5%8D%83%E5%B9%B4%E7%89%88%E6%9D%83%E6%B3%95%E6%94%BF%E7%AD%96",
    accommodations: "残障人士/便利设施",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994433-%E6%AE%8B%E7%96%BE-%E6%97%A0%E9%9A%9C%E7%A2%8D",
    technicalSupport: "技术支持",
    chatSupport: "如需支持，请在本页右下角与我们的团队聊天。"
  },
  instruction: {
    eppCopyright:
      "本评估包含了来自 SalesAP ©1995 和 CSAP ©2002 的复制材料，Multi-Health Systems Inc. 保留所有权利。",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "您的评估已暂停。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "在测试进行期间，请<b>不要</b>离开您的浏览器窗口或在评估区域之外点击。",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "如果您遇到互联网连接问题，请",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "点击此处",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2: "获取一些故障排除提示。",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "为测试安全起见，离开评估会造成您被<b>锁定</b>，这可能会阻止您完成评估。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "为测试安全起见，如果您再离开评估<b>两次</b>，您将被<b>锁定</b>。",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>为测试安全起见，如果您再次离开评估，您的页面将被锁定。</b>",
    BUTTON_RESUME: "恢复"
  },
  error: {
    common: {
      UH_OH: "啊哦！"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "由于网络问题，无法加载。请检查您的互联网连接，稍后再刷新页面。",
      MESSAGE_PARAGRAPH_2_SECTION_1: "如果问题依旧，请查看我们的",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "支持常见问题解答",
      MESSAGE_PARAGRAPH_2_SECTION_2: "。",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853529-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98"
    }
  }
};
